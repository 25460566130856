import React, { useEffect, useState } from "react";
import HomeTable from "../../container/HomeContainer/HomeTable";
import { fetchAllTenders } from "../../features/Tender/TenderSlice";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined } from "@ant-design/icons";
import { Input, Space, Switch, Dropdown, Menu, DatePicker } from "antd";
import Skeleton from "react-loading-skeleton";
import debounce from "lodash/debounce";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

function HomePage() {
  
  const dateFormat = 'YYYY-MM-DD';
  const [currentDate, setCurrentDate] = useState("2024-12-01")
  // Update Current Date
  useEffect(() => {
    function getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    setCurrentDate(getCurrentDate())
  }, [])

  const { tenderFecthStatus, tenderArr } = useSelector((state) => state.tender);
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    input: "",
    check: true,
    dates: null,
    currentSelectedDropdown: 1,
  });

  useEffect(() => {
    dispatch(fetchAllTenders());
  }, [dispatch]);

  useEffect(() => {
    filterData();
  }, [tenderArr, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const debouncedInputChange = debounce((value) => {
    handleFilterChange("input", value);
  }, 300);

  const filterData = () => {
    let tender_data = [...tenderArr];

    const userID = localStorage.getItem("id");

    const is_admin_val = localStorage.getItem("is_admin")
    const is_admin = is_admin_val === "true" ? true : false;


    let data = tender_data
    
    // if not admin then filter by created_by
    if(!is_admin){ 
      if(userID){
        data = tender_data.filter((item) => item.created_by === Number(userID));
      }
    }

    // Filter by date range
    if (filters.dates && filters.dates.length > 0) {
      const [startDate, endDate] = filters.dates.map((date,index) =>{
        const dateObj = new Date(date);
        if (index === 1) {
          // Set end date to the end of the day
          dateObj.setHours(23, 59, 59, 999);
        }
        return dateObj.getTime();
    });
      data = data.filter((item) => {
        const createdAt = new Date(item.created_at).getTime();
        return (
          (!startDate || createdAt >= startDate) &&
          (!endDate || createdAt <= endDate)
        );
      });
    }

    // Filter by input
    if (filters.input) {
      const searchKey = filters.check ? "tender_name" : "tender_number";
      data = data.filter((item) =>
        item[searchKey]?.toLowerCase().includes(filters.input.toLowerCase())
      );
    }

    // Sort data
    if (filters.currentSelectedDropdown === 1) {
      data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else if (filters.currentSelectedDropdown === 2) {
      data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    }

    setFilteredData(data);
  };

  const dropDownItems = [
    { key: 1, title: "Newest First" },
    { key: 2, title: "Oldest First" },
    { key: 0, title: "Clear" },
  ];

  const menu = (
    <Menu>
      {dropDownItems.map((item) => (
        <Menu.Item
          key={item.key}
          onClick={() => handleFilterChange("currentSelectedDropdown", item.key)}
        >
          {item.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="w-full h-full flex flex-col gap-[30px]">
      <div className="flex-none w-full pl-4 pr-4 flex items-center justify-between">
        <div className="flex flex-col gap-[10px] w-full">
          <div className="flex items-center justify-between">
            <span className="text-[rgb(141,102,65)] font-bold text-2xl">
              My Tender Status
            </span>
            <div className="flex gap-2 items-center text-[#33ACFF] font-bold">
              Tender Count:{" "}
              {tenderFecthStatus === "loading" ? (
                <Skeleton width={35} height={16} />
              ) : (
                filteredData?.length
              )}
            </div>
          </div>
          <div className="flex justify-space gap-4 items-center">
            <div className="flex gap-4 items-center" style={{ width: "60%" }}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="TenderName"
                  unCheckedChildren="TenderNumber"
                  onClick={() =>
                    handleFilterChange("check", !filters.check)
                  }
                  defaultChecked
                />
              </Space>
              <Input
                style={{ width: "100%" }}
                placeholder={
                  filters.check ? "Search TenderName" : "Search TenderNumber"
                }
                onChange={(e) => debouncedInputChange(e.target.value)}
              />
            </div>
            <div className="flex gap-4 items-center">
              <RangePicker
                onChange={(dates) => handleFilterChange("dates", dates)}
                format="YYYY-MM-DD"
                minDate={dayjs('2024-05-01', dateFormat)}
                maxDate={dayjs(currentDate, dateFormat)}
              />
              <Dropdown overlay={menu} trigger={["click"]} className="cursor-pointer border  border-blue-gray-100 text-[#4D7AFC] px-2">
                <Space>
                  {filters.currentSelectedDropdown === 0
                    ? "Sort"
                    : filters.currentSelectedDropdown === 1
                    ? "Newest First"
                    : "Oldest First"}
                  <DownOutlined />
                </Space>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-grow w-full overflow-y-auto shadow-xl">
        <HomeTable
          data={filteredData}
          status={tenderFecthStatus}
          type="My Tender Status"
        />
      </div>
    </div>
  );
}

export default HomePage;
