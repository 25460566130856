import React from "react";
import { Breadcrumbs } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function TenderBreadCrumb() {
  const { tenderStat, tenderStatDetail, activeTenderType } = useSelector(
    (state) => state.tenderDetail
  );
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (activeTenderType === "Tenders In Progress") {
      navigate("/progress-tenders");
    } else if (activeTenderType === "Tenders Completed") {
      navigate("/completed-tenders");
    } else if (activeTenderType === "My Tender Status") {
      navigate("/all-tenders");
    }else if(activeTenderType === "Tenders To Be Started"){
      navigate("pending-tenders")
    } else if(activeTenderType === "Archived"){
      navigate("/archived");
    }
  };

  return (
    <div>
      <Breadcrumbs className="bg-[none] text-[#767676] font-bold text-xl">
        {/* <div className="text-[blue] font-bold text-base" onClick={() => navigate("/all-tenders")}> */}
        <div
          className="text-[blue] font-bold text-base"
          onClick={handleNavigate}
        >
          All
        </div>
        <div className="text-[blue] font-bold text-base">
          {tenderStatDetail?.["tender_name"]}
        </div>
      </Breadcrumbs>
    </div>
  );
}

export default TenderBreadCrumb;
