import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
} from "@material-tailwind/react";
import ReactPDF from 'react-pdf-js';
import { unwrapResult } from "@reduxjs/toolkit";
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineEdit } from "react-icons/md";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { ReactComponent as DocumentIcon } from "../../Assets/Images/Home/Document.svg"
import { FaCheckCircle } from "react-icons/fa";
import ReviewModal from "./ReviewModal";
import FeedbackModal from "./FeedbackModal";
import { editPQR, updatePqrValue } from "../../features/TenderDetail/TenderDetailSlice";
import { useDispatch, useSelector } from "react-redux";



const ProcessedPQRList = ({
  processedPQR,
  setProcessedPQR,
  processedPQRData,
  docID,
  bidderId
}) => {
  const [reviewModal, setReviewModal] = useState(false)
  const [selectedModal, setSelectedModal] = useState(null)
  const [reviewDone, setReviewDone] = useState(false)
  const [numPages, setNumPages] = useState(null);
  const {bidderDetail} = useSelector(state => state.tenderDetail)
  const [pageNumber, setPageNumber] = useState(1);


  const onLoadSuccess = (totalPages) => {
    console.log("totalPages",totalPages)
    setNumPages(totalPages);
  };

  const [error, setError] = useState(null);
  const handleLoadError = (error) => {
    setError("Failed to load PDF. Please check the file URL.");
    console.error("Error loading PDF:", error);
    alert("Failed to load PDF. Please check the file and try again.");
  };

  // Callback to change the current page
  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= numPages) {
      setPageNumber(pageNumber); // Set the current page number
    }
  };

 

  const dispatch = useDispatch()


  const handleOpen = () => {
    setProcessedPQR(!processedPQR);
    reset()
  }


  const [handleSourceClick, setHanleSourceClick] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [feedbackModalStatus, setFeedbackModalStatus] = useState(false)
  const [feedbackModal, setFeedbackModal] = useState(null)
  const [pqrId, setPqrId] = useState(null)
  const [selectedPqr, setSelectedPqr] = useState(null)
  const [selectedPqrValue, setSelectedPqrValue] = useState(null)
  const [pqrReviewd, setPqrReviewed] = useState(false)

  useEffect(() => {
    setPqrReviewed(bidderDetail?.find(bidder => bidder.id === bidderId)?.pqrs_reviewed ?? false)
  }, [bidderDetail, bidderId])

  const [sourceUrl, setSourceUrl] = useState(null)
  const [fileType, setFileType] = useState(null)

  // useEffect(() => {
  //   const regex = /#page=(\d+)/;
  //   const result = regex.exec(sourceUrl);
  //   if (result) {
  //     const number = parseInt(result[1], 10);
  //     setPageNumber(number);
  //   }
  // }, [sourceUrl]);

  const docs = [
    {
      // uri:"https://www.aeee.in/wp-content/uploads/2020/08/Sample-pdf.pdf",
      // uri:"https://storage.googleapis.com/iocl-be/tenders/6pBnUegRBJqqYKbp4nXZ/bidder_documents/B.S.%20CONSTRUCTION%20CO/EXP.pdf",
      // fileType: "pdf",
      uri: sourceUrl===null ? "https://www.aeee.in/wp-content/uploads/2020/08/Sample-pdf.pdf" : sourceUrl,
      fileType: fileType===null ? "pdf" : fileType,
      // fileName: "Demo.pdf", 
    },
  ];

  const reset = () => {
    setHanleSourceClick(false);
    setActiveIndex(null)
  }

  const handleSourceButtonClick = (data, index) => {
    // const dataUrl = data?.source ?? "https://storage.googleapis.com/iocl-be/tenders/6pBnUegRBJqqYKbp4nXZ/bidder_documents/B.S.%20CONSTRUCTION%20CO/EXP.pdf#page=10"
    if (!data?.source) {
      alert("No Data Source URL found!!");
      return
    }
    if(data?.source === "NA"){
      alert("No Data Source URL found!!");
      return
    }
    if(!data?.source.includes("https")){
      alert("No Data Source URL found!!");
      return
    }

    const dataUrl = data?.source;


    if(activeIndex === index){
      setActiveIndex(null);
      setSourceUrl(null);
      setHanleSourceClick(false)
      setPageNumber(1); // Reset page number on close
      setNumPages(null); // Reset numPages on close
    }
    else{
      setHanleSourceClick(!handleSourceClick);
      setActiveIndex(index)
      setSourceUrl(dataUrl);

      // Extract file type and page number from URL
      setFileType(extractDataType(dataUrl) || null);

      //  Extract page number from URL
      setPageNumber(extractPageNumberFromUrl(dataUrl) || 1);
    }
  };

  const extractPageNumberFromUrl = (url) => {
    if(url){
        const regex = /#page=(\d+)/;
        const match = url.match(regex);
        return match ? parseInt(match[1], 10) : null;
    }
    return null
  };

  const extractDataType = (url) => {
      if(url){
          const regex = /\.([a-zA-Z0-9]+)(?=(\?|#|$))/;
          const match = url.match(regex);
          return match ? match[1] : null;
      }
      return null
  }

  // useEffect(() => {
  //   if(!handleSourceClick){
  //     setActiveIndex(null)
  //   }
  // }, [handleSourceClick])

  
  

  const reviewClickHandler = () => {
    if(pqrReviewd){
      alert("All PQRs Reviewed!!")
      return
    }
    setSelectedModal("single")
    setReviewModal(true)
  }

  const feedbackClickHandler = (val, pqr_id) => {
    setFeedbackModalStatus(true)
    setFeedbackModal(val)
    setPqrId(pqr_id)
  }

  const editClickHandler = (index, value) => {
    setSelectedPqr(index)
    setSelectedPqrValue(value)
  }

  const editValClickHandler = (val, pqrId, pqrKey) => {
    console.log(val, pqrId, pqrKey)
    if(val === "accept"){
      editPqrValueFunction({docID, pqrId, bidderId, selectedPqrValue, pqrKey})
    }
    else{
      // Reset state
      setSelectedPqr(null)
      setSelectedPqrValue(null)
    }
  }

  const editPqrValueFunction = async(payload) =>{
    try {
      const {docID, pqrId, bidderId, selectedPqrValue, pqrKey} = payload
      await dispatch(editPQR({id: docID, data: {pqr_id: pqrId, bidder_id: bidderId, updated_value: selectedPqrValue}}));

      // Dispatch updatePqrValue action
      dispatch(updatePqrValue({bidderId: bidderId, pqrId: pqrId, pqrKey: pqrKey, pqrValue: selectedPqrValue}))

      // Reset state
      setSelectedPqr(null)
      setSelectedPqrValue(null)
    } 
    catch (error) {
      console.error(error);
    }
    finally {
    }
  }


  
  return (
    <>
        <Dialog size="xxl" open={processedPQR} handler={handleOpen}>

          <ReviewModal modalStatus={reviewModal} setModalStatus={setReviewModal} selectedModal={selectedModal} setReviewDone={setReviewDone} bidderId={bidderId} docID={docID}/>
          <FeedbackModal modalStatus={feedbackModalStatus} setModalStatus={setFeedbackModalStatus} feedbackModal={feedbackModal} docID={docID} pqrId={pqrId} bidderId={bidderId} />
          
          
          <DialogHeader className="flex justify-center text-[#8d6641] relative">
            Generated PQR Data
            <RxCross2 onClick={handleOpen} size={30} color="red" className="cursor-pointer absolute right-[2rem]" />
          </DialogHeader>

          <DialogBody className="h-[85vh] ">
            <div className="flex gap-10 h-full">

              <div style={{width:"40%", border:"1px solid #E9EAEC" , padding:"6px", boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", height: "100%"}} className="flex flex-col gap-4">

                <div className="flex-none">
                  <Button color={pqrReviewd ? "green" : "#212663"} className={`flex items-center gap-2 normal-case text-[12px] rounded-full`} size="sm" onClick={reviewClickHandler}>
                    {
                      pqrReviewd
                      ? 
                        "Review Completed" 
                      : 
                        "Mark Bidder Reviewed"
                    }   
                  <FaCheckCircle size={15}/>
                  </Button>
                </div>

                <div className="flex max-h-full w-full h-full flex-col gap-8 text-[#242325] text-base font-normal overflow-y-scroll py-4 ">
                  {
                  (Array.isArray(processedPQRData) && processedPQRData?.length>0) && 
                  processedPQRData?.map((data, index) => {
                    const validEntry = Object.entries(data).find(
                      ([key]) => key !== "is_accepted" && key !== "source" && key !== "comment"
                    );
                    if (!validEntry) return null;  // If no valid entry, do not proceed
                    const [key, value] = validEntry;
                    
                    return (
                      <>
                        <div key={index} className="flex w-full h-full justify-between items-center gap-y-8 px-4 gap-x-6 pb-4" style={{borderBottom: "1px solid #E9EAEC"}}>
                          <div className="w-full">
                            <span className="font-bold text-[20px]">
                              {`${key} :` ?? "NA"}
                            </span>
                            {
                              selectedPqr === index ? 
                                <div className="w-full flex flex-row items-center border border-[#dedede] ">
                                  <input type="text" value={selectedPqrValue} onChange={(e) => setSelectedPqrValue(e.target.value)} className="w-full p-2 border-none outline-none" />
                                  <div className="flex flex-row items-center gap-4">
                                    <IoMdCheckmark size={20} color="green" className="cursor-pointer" onClick={() => editValClickHandler("accept", index, key)}/>
                                    <RxCross2 size={20} color="red" className="cursor-pointer" onClick={() => editValClickHandler("decline", index, key)}/>
                                  </div>
                                </div>
                              :
                                <div className="flex flex-row items-center gap-4">
                                  <span className={`text-[${(value === "NA" || value === "Not Found") ? "red" :  data?.is_accepted ? "green" : data?.is_accepted === null ? "black": "red"}]`}>{value ?? "NA"}</span>
                                  {
                                    (value === "NA" || value === "Not Found") 
                                    ? 
                                      null 
                                    :
                                    <div>
                                      <MdOutlineEdit size={22} color="grey" className="cursor-pointer" onClick={() => editClickHandler(index, value)}/>
                                    </div>
                                  }
                                </div>
                            }
                          </div>

                          <div className="flex flex-col items-center gap-4">
                            <span
                              className="cursor-pointer"
                              onClick={() => handleSourceButtonClick(data, index)}
                            >
                              <Button style={{backgroundColor:`${activeIndex === index ? "black" : "rgb(33 150 243)"}`, padding:"6px 16px"}}>Source</Button>
                            </span>
                            <div className="flex space-x-2 items-center gap-2">
                              {
                              (value === "NA" || value === "Not Found") ? 
                                <span className="text-[red]">Not Found</span>
                              :
                              data?.is_accepted 
                              ? 
                                <span className="text-[green]">Accepted</span> 
                              :
                              data?.is_accepted === null 
                              ?
                                <>
                                  <IoMdCheckmark size={20} color="green" className="cursor-pointer" onClick={() => feedbackClickHandler("accept", index)}/>
                                  <RxCross2 size={20} color="red" className="cursor-pointer" onClick={() => feedbackClickHandler("decline", index)}/>
                                </>
                              :
                                <span className="text-[red]">Declined</span> 
                              }
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>

              <div style={{width:"60%", border:"0.5px solid #E9EAEC", boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                {
                handleSourceClick ? 
                  <>
                    {/* <div className="w-full max-h-full overflow-scroll">
                      <div
                        style={{
                          backgroundColor: '#444',
                          padding: '2px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <button
                          onClick={() => goToPage(1)}
                          style={{
                            color: '#fff',
                            border: '1.5px solid white',
                            backgroundColor: '#333333',
                            borderRadius: '3px',
                            cursor: 'pointer',
                            padding: '3px 3px',
                          }}
                        >
                          Go to Page 1
                        </button>

                        {numPages && (
                          <div
                            style={{
                              flex: '1',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: '#fff',
                            }}
                          >
                            <p style={{ margin: '0', textAlign: 'center' }}>
                              Page {pageNumber} of {numPages}
                            </p>
                          </div>
                        )}

                        <div style={{ display: 'flex', gap: '5px' }}>
                          <button
                            onClick={() => goToPage(Math.max(pageNumber - 1, 1))}
                            disabled={pageNumber === 1}
                            style={{
                              backgroundColor: '#333333',
                              color: '#fff',
                              border: '1.5px solid white',
                              borderRadius: '3px',
                              cursor: pageNumber === 1 ? 'not-allowed' : 'pointer',
                              padding: '3px 3px',
                            }}
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => goToPage(Math.min(pageNumber + 1, numPages))}
                            disabled={pageNumber === numPages}
                            style={{
                              backgroundColor: '#333333',
                              color: '#fff',
                              border: '1.5px solid white',
                              borderRadius: '3px',
                              cursor: pageNumber === numPages ? 'not-allowed' : 'pointer',
                              padding: '3px 3px',
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                      <div
                        style={{
                          margin: '20px auto',
                          width: '100%',
                          backgroundColor: '#fff',
                          padding: '3px',
                        }}
                      >
                        <ReactPDF
                          file={docs[0].uri}
                          onDocumentComplete={onLoadSuccess}
                          scale={1.0} 
                          page={pageNumber}
                        />
                      </div>
                    </div> */}

                  {
                    fileType === "pdf" 
                    ? 
                      <div className="w-full max-h-full overflow-scroll">
                        <div style={{
                          backgroundColor: '#444',
                          padding: '2px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}>
                          <button
                            onClick={() => goToPage(1)}
                            style={{
                              color: '#fff',
                              border: '1.5px solid white',
                              backgroundColor: '#333333',
                              borderRadius: '3px',
                              cursor: 'pointer',
                              padding: '3px 3px',
                            }}
                          >
                            Go to Page 1
                          </button>

                          {numPages && (
                            <div style={{
                              flex: '1',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: '#fff',
                            }}>
                              <p style={{ margin: '0', textAlign: 'center' }}>
                                Page {pageNumber} of {numPages}
                              </p>
                            </div>
                          )}

                          <div style={{ display: 'flex', gap: '5px' }}>
                            <button
                              onClick={() => goToPage(Math.max(pageNumber - 1, 1))}
                              disabled={pageNumber === 1}
                              style={{
                                backgroundColor: '#333333',
                                color: '#fff',
                                border: '1.5px solid white',
                                borderRadius: '3px',
                                cursor: pageNumber === 1 ? 'not-allowed' : 'pointer',
                                padding: '3px 3px',
                              }}
                            >
                              Previous
                            </button>
                            <button
                              onClick={() => goToPage(Math.min(pageNumber + 1, numPages))}
                              disabled={pageNumber === numPages}
                              style={{
                                backgroundColor: '#333333',
                                color: '#fff',
                                border: '1.5px solid white',
                                borderRadius: '3px',
                                cursor: pageNumber === numPages ? 'not-allowed' : 'pointer',
                                padding: '3px 3px',
                              }}
                            >
                              Next
                            </button>
                          </div>
                        </div>

                        <div style={{
                          margin: '20px auto',
                          width: '100%',
                          backgroundColor: '#fff',
                          padding: '3px',
                        }}>
                          <ReactPDF
                            file={sourceUrl}
                            scale={1.0}
                            page={pageNumber}
                            onDocumentComplete={onLoadSuccess}
                            onError={handleLoadError}
                          />
                        </div>
                      </div>
                    : 
                      <div className="flex justify-center items-center w-full max-h-full  overflow-scroll">
                        <DocViewer className="max-h-full" documents={docs} pluginRenderers={DocViewerRenderers} />
                      </div>
                  }

                  </>
                : 
                  <> 
                    <div className="w-full h-full">
                    <div className=" flex flex-col justify-center items-center w-full h-full font-bold gap-2" >
                      <p className="text-2xl" >You can view Source document here</p>
                      <DocumentIcon style={{width:"8rem"}} />
                      </div>
                    </div>
                  </>
                }
         
              </div>

            </div>
          </DialogBody>

        </Dialog>
    </>
  );
};

export default ProcessedPQRList;
