import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
  } from "@material-tailwind/react";
import { FaInfoCircle } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit';
import 
{ acceptBidderAllPqr, declineBidderAllPqr, markSingleBidderPqrsAsAccepted, markSingleBidderPqrsAsDeclined,
  acceptAllBiddersAllPqr, declineAllBiddersAllPqr, markAllBidderPqrsAsAccepted, markAllBidderPqrsAsDeclined
 } 
from "../../features/TenderDetail/TenderDetailSlice";

const ReviewModal = ({modalStatus, setModalStatus, selectedModal, setReviewDone, bidderId, docID}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const handleOpen = (val) => {
    if(val === "done"){
      setReviewDone(true)
    }
    setModalStatus(!modalStatus);
  }

  // Accept ALl Pqrs for Single Bidder
  const markSingleBidderPqrHandler = (flag) => {
    setSelectedOption(flag)
    bidderPqrValueUpdate({bidderId: bidderId, docID: docID, flag: flag})
  }

  const bidderPqrValueUpdate = async(payload) =>{
    setLoading(true)
    try {
      const {docID, bidderId, flag} = payload
      if(flag === "accept"){
        // Call api
        const result = await dispatch(acceptBidderAllPqr({id: docID, data: {bidder_id: bidderId}}));
        if (acceptBidderAllPqr.fulfilled.match(result)) {
          // Dispatch update action
          dispatch(markSingleBidderPqrsAsAccepted({bidderId: bidderId}))
          // Close Modal
          setModalStatus(!modalStatus);
        }
        else{
          alert("Some error occured while accepting all PQRs");
        }
      }
      else{
        // Call api
        const result = await dispatch(declineBidderAllPqr({id: docID, data: {bidder_id: bidderId}}));
        if (declineBidderAllPqr.fulfilled.match(result)) {
          // Dispatch update action
          dispatch(markSingleBidderPqrsAsDeclined({ bidderId }));
          // Close Modal
          setModalStatus(!modalStatus);
        }
        else{
          alert("Some error occured while declining all PQRs");
        }
      }
      
    } 
    catch (error) {
      alert("Some error occured");
      console.error(error);
    }
    finally {
    setLoading(false)
    }
  }

  // Accept All Pqrs for All Bidders
  const markAllBidderPqrHandler = async(flag) =>{
    setSelectedOption(flag)
    allBidderPqrValueUpdate({docID: docID, flag: flag})
  }

  const allBidderPqrValueUpdate = async(payload) =>{
    setLoading(true)
    try {
      const {docID, flag} = payload
      if(flag === "accept"){
        // Call api
        const result = await dispatch(acceptAllBiddersAllPqr({id: docID, data: {bidder_id: bidderId}}));
        if (acceptAllBiddersAllPqr.fulfilled.match(result)) {
          // Dispatch update action
          dispatch(markAllBidderPqrsAsAccepted())
          // Close Modal
          setModalStatus(!modalStatus);
        }
        else{
          alert("Some error occured while accepting all PQRs");
        }
      }
      else{
        // Call api
        const result = await dispatch(declineAllBiddersAllPqr({id: docID, data: {bidder_id: bidderId}}));
        if (declineAllBiddersAllPqr.fulfilled.match(result)) {
          // Dispatch update action
          dispatch(markAllBidderPqrsAsDeclined());
          // Close Modal
          setModalStatus(!modalStatus);
        }
        else{
          alert("Some error occured while declining all PQRs");
        }
      }
      
    } 
    catch (error) {
      alert("Some error occured");
      console.error(error);
    }
    finally {
    setLoading(false)
    }
  }

  const AllReviewModal = () => {
    return (
    <Dialog open={modalStatus} handler={handleOpen}>

        <DialogHeader className="flex flex-col items-start gap-2">
        <div className="w-full text-[#212663] font-bold flex flex-row items-center gap-2 justify-between">
            <div className="text-[#212663] font-bold">
                Tender information audit completed?
            </div>
            <div className="cursor-pointer">
                  <RxCross2 color={"red"} onClick={() => handleOpen("cancel")}/>
            </div>
        </div>
            <div className="text-[#AAAAAA] text-[16px]">
                You've successfully completed the information audit for this tender.
            </div>
        </DialogHeader>

        <DialogBody className="flex flex-col items-start gap-8">

            <div className="bg-[#f3702219] text-[red] flex flex-row items-center gap-4 px-2 font-normal py-2 rounded-lg">
                <div>
                    <FaInfoCircle size={22}/>
                </div>
                Further reviews or edits will not be possible, and the report will be generated based on the current data.
            </div>
            <div className="w-full flex flex-row items-center gap-8 justify-center">
              <Button 
              loading={(selectedOption==="accept" && loading) ? true : false}
              disabled={(loading) ? true : false}
              onClick={() => markAllBidderPqrHandler("accept")} 
              className="text-[#212663] font-bold bg-[green] text-white px-4 py-2 rounded-full cursor-pointer hover:bg-[#0CC70C]">
                Accept Unreviewed Bidders
              </Button>
              <Button 
              loading={(selectedOption==="decline" && loading) ? true : false}
              disabled={(loading) ? true : false}
              onClick={() => markAllBidderPqrHandler("decline")} 
              className="text-[#212663] font-bold bg-[red] text-white px-4 py-2 rounded-full cursor-pointer hover:bg-[#a91515]">
                Decline Unreviewed Bidders
              </Button>
          </div>

        </DialogBody>
    </Dialog>
    )
  }

  const SingleReviewModal = () => {
    return (
    <Dialog open={modalStatus} handler={handleOpen}>
        <DialogHeader className="flex flex-col items-start gap-2 w-full">
            <div className="w-full text-[#212663] font-bold flex flex-row items-center gap-2 justify-between">
                <span>Audit completed for this bidder ?</span>
                <div className="cursor-pointer">
                  <RxCross2 color={"red"} onClick={() => handleOpen("cancel")}/>
                </div>
            </div>
            <div className="text-[#AAAAAA] text-[16px]">
                By proceeding, you will finalize the extracted information. 
            </div>
        </DialogHeader>
        <DialogBody className="flex flex-col items-start gap-8">
          <div className="bg-[#f3702219] text-[red] flex flex-row items-center gap-4 px-2 font-normal py-2 rounded-lg">
                <div>
                    <FaInfoCircle size={22}/>
                </div>
                Further reviews or edits will not be possible, and the report will be generated based on the current data.
          </div>
          <div className="w-full flex flex-row items-center gap-8 justify-center">
            <Button 
            loading={(selectedOption==="accept" && loading) ? true : false}
            onClick={() => markSingleBidderPqrHandler("accept")} className="text-[#212663] font-bold bg-[green] text-white px-4 py-2 rounded-full cursor-pointer hover:bg-[#0CC70C]">
              Accept Unreviewed PQRs
            </Button>
            <Button 
            loading={(selectedOption==="decline" && loading) ? true : false}
            onClick={() => markSingleBidderPqrHandler("decline")} className="text-[#212663] font-bold bg-[red] text-white px-4 py-2 rounded-full cursor-pointer hover:bg-[#a91515]">
              Decline Unreviewed PQRs
            </Button>
          </div>
        </DialogBody>
        {/* <DialogFooter className="flex flex-row justify-end">
          <div className="text-[#212663] font-bold max-w-[60%]">
            Bidder Name - Master Solutions
          </div>
          <div> 
            <Button
                variant="text"
                color="red"
                onClick={() => handleOpen("cancel")}
                className="mr-1"
            >
                <span>Cancel</span>
            </Button>
            <Button variant="gradient" color="green" onClick={() => handleOpen("done")}>
            <span>Proceed</span>
          </Button>
          </div> 
        </DialogFooter> */}
    </Dialog>
    )
  }

  return (
    selectedModal === "all" ? <AllReviewModal /> : <SingleReviewModal />
  );
};

export default ReviewModal;
