import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { RxCross1 } from "react-icons/rx";
import "@cyntler/react-doc-viewer/dist/index.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "../../ViewDocumentModal.css";
import { useSelector } from "react-redux";

export function ViewDocModal({ viewDocumentModalStatus, setViewDocumentModalStatus, selectedCSUrl, selectedCS }) {
  const { tenderInfoDetail } = useSelector((state) => state.tenderDetail);

  const docUrl = selectedCSUrl || "";
  const fileType = docUrl.split('.').pop().toLowerCase(); 

  const docs = [
    {
      uri: docUrl,
      // fileType: 'csv',
      fileType: 'xlsx',
      fileName: selectedCS, 
    },
  ];

  const handleOpen = (value) => {
    setViewDocumentModalStatus(value);
  };

  return (
    <>
      <Dialog
        open={viewDocumentModalStatus}
        handler={() => handleOpen(false)}
        size="xl"
      >
        <DialogHeader className="flex justify-end">
          <RxCross1 color="red" style={{ cursor: "pointer" }} onClick={() => handleOpen(false)} />
        </DialogHeader>

        <DialogBody className="h-[80vh] overflow-scroll">
          <DocViewer
            documents={docs}
            pluginRenderers={DocViewerRenderers}
          />
        </DialogBody>

        <DialogFooter className="space-x-2"></DialogFooter>
      </Dialog>
    </>
  );
}
