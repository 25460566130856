import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TiPlus } from "react-icons/ti";

import appLogo from "../../Assets/Images/clientIcon.svg";
import homeIcon from "../../Assets/Images/Sidebar/dashboard.svg";
import logoutIcon from "../../Assets/Images/Sidebar/logout.svg";

import allTenderIcon from "../../Assets/Images/Home/viewIcon.svg"
import pendingTenderIcon from "../../Assets/Images/Home/pendingTender.svg"
import completedTenderIcon from "../../Assets/Images/Home/completed.svg"
import progressTenderIcon from "../../Assets/Images/Home/progress.svg"
import archivedIcon from "../../Assets/Images/Home/archived.svg"
import SelectOptions from '../modals/SelectOptions';
import Logout from '../modals/Logout';
import {useDispatch, useSelector } from 'react-redux';
import {setActiveTenderType} from '../../features/TenderDetail/TenderDetailSlice';
// import MainLogo from '../../Assets/Images/Login/main-logo.svg'
import MainLogo from "../../Assets/Images/Home/iocl_logo.png"
import Valiance from '../../Assets/Images/Login/valiance.svg'

function Sidebar() {

  const dispatch = useDispatch();

  const [selectOptionStatus, setSelectOptionStatus] = useState(false);
  const [logoutStatus, setLogoutStatus] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const {activeTenderType} = useSelector((state)=>state.tenderDetail);

  const userIsAdmin = () => {
    if (localStorage.getItem("is_admin") && localStorage.getItem("is_admin") === "true") {
      return true;
    } 
    else {
      return false;
    }
  };
  useEffect(() => {
    setIsAdmin(userIsAdmin());
  }, [])

  const dashboardData = [
    { label: "Dashboard", icon: homeIcon, path: "/home" },
    { label: "My Tender Status", icon: allTenderIcon, path: "/all-tenders", otherPath: ["/view_detail"] },
    { label: "Tenders To Be Processed", icon: pendingTenderIcon, path: "/pending-tenders" },
    { label: "Tenders In Progress", icon: progressTenderIcon, path: "/progress-tenders" },
    { label: "Tenders Completed", icon: completedTenderIcon, path: "/completed-tenders" },
    { label: "Manage PQR", icon: pendingTenderIcon, path: "/manage-pqr" },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  
  const newTenderHandler = () => {
    setSelectOptionStatus(true)
  }

  useEffect(()=>{
    if(location?.pathname === "/manage-pqr" || dashboardData[5]?.label === "Manage PQR"){
      // dispatch(setActiveTenderType(""));
    }
  },[activeTenderType])

  const checkIfActive = (data) =>{
    if(location.pathname === "Dashboard" || location.pathname === "manage-pqr" || dashboardData.label === "Manage PQR"){
      // dispatch(setActiveTenderType(""));
    } 
    return location.pathname === data.path || (data.otherPath && data.otherPath.includes(location.pathname));
  }


  return (
    <div className='w-[280px] h-full' >
      <div className='bg-[#FFFFFF] rounded-xl w-full h-full p-1 shadow-xl' style={{border: "1px solid #E5E5E5"}}>
        <div className='w-full h-full flex flex-col justify-between'>
          <div className="flex flex-col gap-4 px-4 pt-2">
            <div className='flex flex-col items-start'>
              <div className='flex items-center text-[#242325] justify-center gap-4  mb-3'>
                  <img src={MainLogo} alt="app" className='h-[50px]' />
                  <div className='text-[#332110] font-bold text-[15px] text-center' style={{color: "#8D6641",  width: "60%"}}>AI-Powered Tender Processing Tool</div>
              </div>

              <div onClick={newTenderHandler} className='flex items-center justify-center w-full'>
                <div className='flex items-center gap-2 border rounded-3xl cursor-pointer bg-[#D1ECFE] px-8 py-2 shadow-md hover:shadow-xl'>
                  <TiPlus size={20}/>
                  <span className='font-semibold text-sm'>Process A New Tender</span>
                </div>
              </div>
            </div>

            <div className='flex flex-col gap-2'>
              <div className='text-[#AAAAAA] font-semibold text-[1rem] leading-10' style={{ borderBottom: "1px solid #E5E5E5" }}>
                Main Menu
              </div>
              <div className='flex flex-col gap-1 font-semibold text-[#126568]'>
                {
                  dashboardData.map((data, i) => (
                    <div
                      key={i}
                      onClick={() => navigate(data.path)}
                      className={`flex items-start gap-3 cursor-pointer border border-transparent rounded-lg px-2 py-2
                        ${(checkIfActive(data) || data?.label === activeTenderType)  ? "bg-[#E5F7F8] text-black" : "hover:bg-[#E5F7F8] hover:text-black"}`}
                    > 
                      <img src={data.icon} alt={data.label} className={location.pathname === data.path ? 'text-black' : ''} style={{width: "35px"}}/>
                      <span className='text-md'>{data.label}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>

          {/* Logout button */}
          <div className="flex flex-col items-start gap-4">
            <div className='flex items-center gap-3 cursor-pointer ml-2' onClick={() => setLogoutStatus(true)}>
              <img src={logoutIcon} alt="logout" />
              <span className='text-[#F23B3B] font-semibold hover:text-[red]'>Logout</span>
            </div>
            
            {/* Valiance Logo */}
            <div className='flex justify-center w-full'>
              <img src={Valiance} alt="Valiance Logo" className='w-[250px]' />
            </div>
          </div>
        </div>
      </div>
      
      <SelectOptions selectOptionStatus={selectOptionStatus} setSelectOptionStatus={setSelectOptionStatus}/>
      <Logout logoutStatus={logoutStatus} setLogoutStatus={setLogoutStatus}/>
    </div>
  );
}

export default Sidebar;
