import React from 'react'
import LoginContainer from '../../container/Credentials/LoginUser';
import Background from '../../Assets/Images/Login/iocl.jpg';
import MainLogo from '../../Assets/Images/Login/main-logo.svg';
import '../../css/loginuser.css';

const LoginPage = () => {
  return (
    <main>
    <div className="login">
        <div className="login-image">
            <img src={Background} alt="login-background" className="carousel" />
        </div>
        <div className="login-Content">
            <div className="login-Content-logo">
                <img src={MainLogo} alt="login-background" className="carousel" />
                <p classname="login-content-heading-text">GEN AI Powered Tender Evaluation</p>
            </div>
            <div className="login-Content-heading">
                <h3>HOD Sign in</h3>
            </div>
            <LoginContainer />
        </div>
    </div>
</main>
  );
};

export default LoginPage