import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Checkbox } from "antd";
import { ConfigProvider } from "antd";
import { toast } from 'react-hot-toast';
import { loginUser } from "../../features/Login/UserSlice";
import { unwrapResult } from '@reduxjs/toolkit';
import valiance from '../../Assets/Images/Login/valiance.svg';
import GoogleLogo from '../../Assets/Images/Login/cloud.svg';
import '../../css/loginuser.css';

const LoginForm = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async (values) => {
        try {
            setLoginLoading(true);
            const resultAction = await dispatch(loginUser(values));
            const user = unwrapResult(resultAction);

            localStorage.setItem("is_admin", user?.is_admin ?? false);

            if (user?.token) {
                toast.success('Successfully logged in');
                localStorage.setItem("Authorization", user?.token);
                localStorage.setItem("id", user?.id);
                setTimeout(() => {
                    setLoginLoading(false);
                    navigate("/home");
                }, 3000); 
                navigate("/home");
            } else {
                toast.error('No token received');
            }
        } catch (error) {
            console.error(error);
            toast.error('Login failed');
        } finally {
            setLoginLoading(false);
        }
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: "#FFE60A",
                        defaultBg: "#FFE60A",
                        colorPrimaryHover: "#dfcb21",
                    },
                },
            }}
        >
                {loginLoading && (
                <div className="powerby-container absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 z-50">
                    <div className="content-wrapper text-center">
                        <p className="powered-text">Powered by</p>
                        <img src={valiance} alt="Valiance Logo" className="footer-loading-logo-login" />
                    </div>
                </div>
            )}
            <Form
                name="login_form"
                className="login-form"
                size="large"
                layout="vertical"
                onFinish={handleLogin} // Handling form submission via onFinish
            >
                <Form.Item
                    name="username"
                    label="User Name"
                    rules={[{ required: true, message: "Please input your User Name!" }]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="User Name"
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true, message: "Please input your Password!" }]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Password"
                        iconRender={(visible) => visible ? <LockOutlined /> : <LockOutlined />}
                    />
                </Form.Item>
                <Form.Item>
                <div className="flex items-center justify-between">
                    <Checkbox>Keep me logged in</Checkbox>
                    {/* <div
                    className="text-sm text-blue-600 hover:underline font-semibold cursor-pointer"
                    onClick={() => navigate('/forgot_password')}
                    >
                    Forgot your password?
                    </div> */}
                </div>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        block
                        style={{ color: "black" }}
                        loading={loginLoading}
                    >
                        {loginLoading ? 'Signing in...' : 'Login'}
                    </Button>
                </Form.Item>
            </Form>

            {/* Footer Section */}
            <div className='footer-text-login'>
                <div className='footer-powered-by'>
                    Powered by
                    <a href="https://valiancesolutions.com/" target="_blank" rel="noopener noreferrer">
                        <img src={valiance} alt="Valiance Logo" className="footer-loading-logo-login" />
                    </a>
                </div>
                <img src={GoogleLogo} alt="Google Cloud Partner Logo" className="google-logo" />
            </div>
            
        </ConfigProvider>
    );
};

export default LoginForm;
