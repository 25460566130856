import React, { useState, useRef } from 'react'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
  } from "@material-tailwind/react";
  import { Textarea } from "@material-tailwind/react";
  import { AiFillLike } from "react-icons/ai";
  import { AiFillDislike } from "react-icons/ai";
  import { acceptPQR, declinePQR, updatePqrStatus } from '../../features/TenderDetail/TenderDetailSlice';
  import { useDispatch, useSelector } from 'react-redux';
  import { Toaster } from 'react-hot-toast';


const FeedbackModal = ({modalStatus, setModalStatus, feedbackModal, docID, pqrId, bidderId}) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleOpen = (val) => {

      if(val === "accept" && feedbackModal === "accept"){
        pqrAcceptDeclineUpdateFun({docID, bidderId, pqrId, feedbackModal})
        return
      }
      else if(val === "accept" && feedbackModal === "decline"){
        pqrAcceptDeclineUpdateFun({docID, bidderId, pqrId, feedbackModal})
        return
      }

      setModalStatus(!modalStatus);
  }


  const pqrAcceptDeclineUpdateFun = async(payload) =>{
    try {
      setLoading(true)
      const {docID, pqrId, bidderId, feedbackModal} = payload
      
      // User Input
      const userInput = document.getElementById("userInput").value

      if(feedbackModal === "accept"){
        dispatch(acceptPQR({id: docID, data: {pqr_id: pqrId, bidder_id: bidderId, comment: userInput}}))
        // Update PQR Status
        dispatch(updatePqrStatus({bidderId: bidderId, pqrId: pqrId, pqrStatus: true}))
        // Close Modal
        setModalStatus(false)
      }
      else if(feedbackModal === "decline"){
        dispatch(declinePQR({id: docID, data: {pqr_id: pqrId, bidder_id: bidderId, comment: userInput}}))
        // Update PQR Status
        dispatch(updatePqrStatus({bidderId: bidderId, pqrId: pqrId, pqrStatus: false}))
        // Close Modal
        setModalStatus(false)
      }
    } 
    catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false)
    }
  }






  const AcceptModal = () => {
      return (
        <Dialog open={modalStatus}>
            <DialogHeader className="flex flex-row items-center gap-2 p-0">
                <div className="text-[#212663] font-bold w-full p-4 flex items-center gap-2" style={{"borderBottom": "1px solid rgba(0, 0, 0, 0.1)"}}>
                  <AiFillLike color='green' size={25}/>
                  Provide Additional Feedback
                </div>
            </DialogHeader>

            <DialogBody className='w-full' style={{background: "linear-gradient(293.59deg, #E5F3FD -25.19%, #F9FBFB 69.6%)", borderBottom: "1px solid rgba(0, 0, 0, 0.1)"}}>
                <div className="w-full text-[black] flex flex-col items-start justify-start gap-4 px-2 font-normal py-2 rounded-lg" >
                  <span className='text-[#0E0E0F] font-bold text-lg'>Add Feedback :</span>
                  <Textarea label="Add your feedback here..." size="lg" color="blue-gray" id="userInput" />
                </div>
            </DialogBody>

            <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={() => handleOpen("cancel")}
                className="mr-1"
              >
                <span>Cancel</span>
              </Button>
              <Button variant="gradient" color="green" loading={loading ? true : false} className='rounded-full' onClick={() => handleOpen("accept")} >
                <span>Submit Feedback</span>
              </Button>
            </DialogFooter>
        </Dialog>
    )
  }

  const DeclineModal = () => {
      return (
        <Dialog open={modalStatus}>
          {/* <Toaster position="top-center" /> */}
            <DialogHeader className="flex flex-row items-center gap-2 p-0">
                <div className="text-[#212663] font-bold w-full p-4 flex items-center gap-2" style={{"borderBottom": "1px solid rgba(0, 0, 0, 0.1)"}}>
                  <AiFillDislike color='red' size={25}/>
                  Provide Additional Feedback
                </div>
            </DialogHeader>

            <DialogBody className='w-full' style={{background: "linear-gradient(293.59deg, #E5F3FD -25.19%, #F9FBFB 69.6%)", borderBottom: "1px solid rgba(0, 0, 0, 0.1)"}}>
                <div className="w-full text-[black] flex flex-col items-start justify-start gap-4 px-2 font-normal py-2 rounded-lg" >
                  <span className='text-[#0E0E0F] font-bold text-lg'>Add Feedback :</span>
                  <Textarea label="Add your feedback here..." size="lg" id="userInput" color="blue-gray" />
                </div>
            </DialogBody>

            <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={() => handleOpen("cancel")}
                className="mr-1"
              >
                <span>Cancel</span>
              </Button>
              <Button variant="gradient" color="green" className='rounded-full' loading={loading ? true : false} onClick={() => handleOpen("accept")} >
                <span>Submit Feedback</span>
              </Button>
            </DialogFooter>
        </Dialog>
    )
  }


  return (
    feedbackModal==="accept" ? <AcceptModal /> : <DeclineModal />
  )
}

export default FeedbackModal