import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Modal, Input, Form, Tabs, Row, Col, Card } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined,CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { addNewPqr, editPqr, deletePqr, proposeNewPqr, proposePqrList, adminReviewPqr } from '../../features/managePqr/ManagePQRSlice'; 
import { fetchPqrList } from '../../features/TenderDetail/TenderDetailSlice';
import { setActiveTenderType } from '../../features/TenderDetail/TenderDetailSlice';
import { PencilIcon } from "@heroicons/react/24/solid";
import { Chip } from "@material-tailwind/react";

const { TextArea } = Input;

const { TabPane } = Tabs;

const ManagePQR = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('Authorization');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editingPqr, setEditingPqr] = useState(null);
  const [deletingPqr, setDeletingPqr] = useState(null);
  const [pqrRequestdataSource,setPqrRequestdataSource] = useState(null);
  const [tab, setTab] = useState("pqr-list");
  const isAdmin = localStorage.getItem("is_admin") === "true";
  console.log("isAdmin",isAdmin)

  const { pqrListData, pqrListStatus, totalPqrCount } = useSelector((state) => state.tenderDetail);
  const { pqrRequestsList } = useSelector((state) => state.managepqr);
  const [editMode, setEditMode] = useState(null);
  const [comments, setComments] = useState({}); // To manage updated comments for each row

  const handleEditClick = (record) => {
    setEditMode(record.key); // Set the current row in edit mode
  };

  const handleSaveComment = (record, value) => {
    setComments({ ...comments, [record.key]: value }); 
  };

  const handleAction = async (record, actionType, comment) => {
    console.log("Dispatching action:", { token, pqr_id: record.id, comment: comment, status: `${actionType === 'approve' ? 'accepted' : 'declined'}` });

    await dispatch(adminReviewPqr({ token, pqr_id:record.id, comment:comment , status:`${actionType === 'approve' ? 'accepted' : 'declined'}` }));
    await dispatch(proposePqrList({token}));
    await dispatch(fetchPqrList());
    setEditMode(null); 
};

const convertToIndianDate = (isoDateString) => {
  if (!isoDateString) return '';

  const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Kolkata' };
  const date = new Date(isoDateString);

  if (isNaN(date)) return '';

  return date.toLocaleDateString('en-IN', options);
};



  function callback(key) {

    console.log(key);
    if(key=="1"){
      setTab("pqr-list")
    }
    else if(key=="2"){
      setTab("pqr-requests")
    }
  }

  useEffect(()=>{
    dispatch(setActiveTenderType(""));
 },[])

  useEffect(() => {
    dispatch(fetchPqrList());
    dispatch(proposePqrList({token}));
  }, [dispatch]);

  const showModal = (pqr) => {
    setIsModalVisible(true);
    if (pqr) {
      setEditingPqr(pqr);
      form.setFieldsValue({ pqrName: pqr });
    } else {
      setEditingPqr(null);
      form.resetFields();
    }
  };

  const showDeleteModal = (pqr) => {
    setDeletingPqr(pqr);
    setIsDeleteModalVisible(true);
  };

  const handleDelete = () => {
    dispatch(deletePqr({ index: pqrListData.indexOf(deletingPqr), token }))
      .unwrap()
      .then(() => {
        setIsDeleteModalVisible(false);
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleOk = async () => {
    setLoading(true); // Move loading state change to the start to show loading immediately
    try {
      await form.validateFields(); // Wait for the form validation to complete
      const values = form.getFieldsValue(); // Get the form values
  
      if (isAdmin) {
        if (editingPqr) {
          await dispatch(editPqr({ index: pqrListData.indexOf(editingPqr), new_pqr_name: values.pqrName, token }))
            .unwrap()
            .then(async () => { // Use async to wait for subsequent dispatch actions
              await dispatch(fetchPqrList());
              await dispatch(proposePqrList({ token }));
              setIsModalVisible(false);
              form.resetFields();
            })
            .catch(() => {
              setLoading(false); // Set loading to false if an error occurs
            });
        } else {
          await dispatch(addNewPqr({ pqr_name: values.pqrName, token }))
            .unwrap();
  
          // Wait for fetchPqrList and proposePqrList actions to complete
          await dispatch(fetchPqrList());
          await dispatch(proposePqrList({ token }));
  
          setIsModalVisible(false);
          form.resetFields();
        }
      } else {
        if (editingPqr) {
          // Handle case when editingPqr is true for non-admin (if needed)
        } else {
          await dispatch(proposeNewPqr({ pqr_name: values.pqrName, token }))
            .unwrap();
  
          // Wait for fetchPqrList and proposePqrList actions to complete
          await dispatch(fetchPqrList());
          await dispatch(proposePqrList({ token }));
  
          setIsModalVisible(false);
          form.resetFields();
        }
      }
    } catch (error) {
      console.log('Validation Failed:', error);
    } finally {
      setLoading(false); // Ensure loading is set to false when the process is done
    }
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const renderPqrStats = (stats, styles) => (
    <Row gutter={[6, 16]}>
      {stats.map(({ title, key, style }) => (
        <Col span={6} key={key}>
          <Card
            size="small"
            title={<span style={cardStyles.title}>{title}</span>}
            bordered={true}
            style={{ ...cardStyles.base, ...cardStyles[style] }}
          >
            {pqrRequestsList?.proposed_pqr_counts?.[0]?.[key] ?? "N/A"}
          </Card>
        </Col>
      ))}
    </Row>
  );

  const columns = [
    {
      title: 'PQR List',
      dataIndex: 'pqr',
      key: 'pqr',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div className="flex gap-4">
          <Button icon={<EditOutlined />} type="primary" onClick={() => showModal(record.pqr)} />
          <Button
            icon={<DeleteOutlined style={{ color: 'white' }} />}
            style={{
              backgroundColor: 'red',
              border: 'none',
              color: 'white',
            }}
            onClick={() => showDeleteModal(record.pqr)} // Trigger delete modal
          />
        </div>
      ),
    },
  ];

  const dataSource = Array.isArray(pqrListData) ? pqrListData.map((pqr, index) => ({
    key: index + 1,
    pqr,
  })) : [];


  useEffect(() => {
    console.log("pqrRequestsList updated:", pqrRequestsList);
    let newData = Array.isArray(pqrRequestsList?.proposed_pqrs)
        ? pqrRequestsList?.proposed_pqrs.map((pqr, index) => ({
            key: index + 1,
            ...pqr,
          }))
        : [];
    setPqrRequestdataSource(newData);
    
  }, [pqrRequestsList]);


  

const cardStyles = {
  base: {
    wordBreak: "break-word",
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1.0rem", // Increase font size
  },
  blue: {
    backgroundColor: "#2196f3", // Blue background
  },
  yellow: {
    backgroundColor: "#ffc107", // Yellow background
  },
  green: {
    backgroundColor: "#4caf50", // Green background
  },
  red: {
    backgroundColor: "#f44336", // Red background
  },
  title: {
    color: "white",
    fontWeight: "bold",
    fontSize: "1.0rem", // Larger font size for title
  },
};


  const pqr_request_columns = [
    {
      title: 'PQR Name',
      dataIndex: 'pqr_name',
      key: 'pqr_name',
      render: (text) => (
        <div className="custom-css">
          {text}
        </div>
      ),
      minWidth: 100, // Added minWidth
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (record) => (
        <Chip
          className="custom-css text-center"
          variant="ghost"
          size="sm"
          value={record === "pending" ? "Pending" : record === "accepted" ? "Accepted" : "Declined"}
          color={record === "pending" ? "yellow" : record === "accepted" ? "green" : "red"}
          backgroundColor={record === "yellow" ? "#ffc107" : record === "accepted" ? "green" : "red"}
        />
      ),
      minWidth: 100, // Added minWidth
    },
    {
      title: 'Raised Date',
      dataIndex: 'raised_date',
      key: 'raised_date',
      render: (text) => (
        <div className="custom-css">
          {text ? convertToIndianDate(text):<span style={{ color: '#888' }}>N/A</span>}
        </div>
      ),
      minWidth: 100, // Added minWidth
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      width: 300,
      minWidth: 100, // Added minWidth
      render: (text, record) => {
        const isEditing = editMode === record.key;
        return isEditing ? (
          <TextArea
            className="custom-css"
            rows={2}
            defaultValue={comments[record.key] || text || ''}
            placeholder="Enter comment"
            onBlur={(e) => handleSaveComment(record, e.target.value)}
            autoFocus
          />
        ) : (
          comments[record.key] || text || <span style={{ fontStyle: 'italic', color: '#ccc' }}>N/A</span>
        );
      },
    },
    {
      title: 'Accepted/Rejected Date',
      dataIndex: 'decision_date',
      key: 'decision_date',
      render: (text) => (
        <div className="custom-css">
          {text ? convertToIndianDate(text):<span style={{ color: '#888' }}>N/A</span>}
        </div>
      ),
      minWidth: 100, // Added minWidth
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div className="flex gap-4 custom-css">
          {editMode == record.key ? (
            <>
              <div>
                <Button
                  icon={<CheckOutlined />}
                  style={{ backgroundColor: '#4caf50', border: 'none', color: 'white' }}
                  onClick={() => handleAction(record, 'approve', comments[record.key]) }
                />
              </div>
              <div>
                <Button
                  icon={<CloseOutlined />}
                  style={{ backgroundColor: 'red', border: 'none', color: 'white' }}
                  onClick={() => {
                    console.log("Reject button clicked");
                    handleAction(record, 'reject', comments[record.key] ?? '');
                  }}
                />
              </div>
            </>
          ) : record.status === 'pending' ? (
            <Button
              icon={<PencilIcon className="h-4 w-3" />}
              onClick={() => handleEditClick(record)}
            ></Button>
          ) : (
            <span style={{ color: '#888' }}>N/A</span> // Disable action for non-Pending rows
          )}
        </div>
      ),
      minWidth: 100, // Added minWidth
    },
  ];
  
  const pqr__normal_user_request_columns = [
    {
      title: 'PQR Name',
      dataIndex: 'pqr_name',
      key: 'pqr_name',
      render: (text) => (
        <div className="custom-css">
          {text}
        </div>
      ),
      minWidth: 100, // Added minWidth
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        <Chip
          className="custom-css text-center"
          variant="ghost"
          size="sm"
          value={text === "pending" ? "Pending" : text === "accepted" ? "Accepted" : "Declined"}
          color={text === "pending" ? "yellow" : text === "accepted" ? "green" : "red"}
          backgroundColor={text === "yellow" ? "#ffc107" : text === "accepted" ? "green" : "red"}
        />
      ),
      minWidth: 100, // Added minWidth
    },
    {
      title: 'Raised Date',
      dataIndex: 'raised_date',
      key: 'raised_date',
      render: (text) => (
        <div className="custom-css">
          {text ? convertToIndianDate(text):<span style={{ color: '#888' }}>N/A</span>}
        </div>
      ),
      minWidth: 100, // Added minWidth
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => (
        <div className="custom-css">
          {text}
        </div>
      ),
      width: 300,
      minWidth: 100, // Added minWidth
    },
    {
      title: 'Accepted / Rejected Date',
      dataIndex: 'decision_date',
      key: 'decision_date',
      render: (text) => (
        <div className="custom-css">
          {text ? convertToIndianDate(text):<span style={{ color: '#888' }}>N/A</span>}
        </div>
      ),
      minWidth: 100, // Added minWidth
    }
  ];
  
   

  return (
    <div className="w-full h-full bg-[#FFFFFF] flex flex-col items-center gap-6 border border-blue-gray-50 shadow-xl rounded-[10px] p-8">
  <div className="flex justify-between w-full">
    <h1 className="text-3xl text-[#8d6641]">Manage PQR</h1>
    <div className="flex items-center justify-center gap-5">
      <h1 className="font-bold text-[#33ACFF]">
        {isAdmin
          ? tab === "pqr-list"
            ? `Total PQR: ${totalPqrCount || 0}`
            : `Total PQR Requests: ${pqrRequestsList?.proposed_pqr_counts?.[0]?.total_count ?? 0}`
          : `Total PQR: ${pqrRequestsList?.proposed_pqr_counts?.[0]?.total_count ?? 0}`}
      </h1>
      <Button
        type="primary"
        style={{
          backgroundColor: 'rgb(209, 236, 254)',
          borderRadius: '20px',
          border: 'none',
          color: 'black',
        }}
        icon={<PlusOutlined />}
        onClick={() => showModal(null)}
      >
        Add PQR
      </Button>
    </div>
  </div>
  {isAdmin ? (
    <Tabs className="w-full" onChange={callback} type="card">
      <TabPane className="w-full h-full" tab="PQR List" key="1">
        <div className="overflow-y-auto h-full">
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            className="managepqr-container custom-css"
            sticky
            loading={pqrListStatus === 'loading'}
            bordered
            scroll={{ y: 400 }} // Specify the height to make the table scrollable
          />
        </div>

        {/* Add/Edit PQR Modal */}
        <Modal
          title={editingPqr ? 'Edit PQR' : 'Add PQR'}
          centered
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Submit"
          cancelText="Cancel"
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              {isAdmin ? "Update" : "Request"}
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical" name="pqrForm">
            <Form.Item
              name="pqrName"
              label="PQR Name"
              rules={[{ required: true, message: 'Please enter the PQR name!' }]}
            >
              <Input placeholder="Enter PQR name" style={{ width: '100%' }} />
            </Form.Item>
          </Form>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          title="Delete PQR"
          centered
          visible={isDeleteModalVisible}
          onOk={handleDelete}
          onCancel={handleDeleteCancel}
          footer={[
            <Button key="cancel" onClick={handleDeleteCancel}>
              Cancel
            </Button>,
            <Button key="delete" type="primary" style={{ backgroundColor: 'red', borderColor: 'red' }} onClick={handleDelete}>
              Delete
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this PQR?</p>
        </Modal>
      </TabPane>

      <TabPane className="h-full" tab="PQR Requests" key="2">
        <div className="flex flex-col w-full gap-4">
        {renderPqrStats(
          [
            { title: 'Total Requests', key: 'total_count', style: 'blue' },
            { title: 'Pending Requests', key: 'pending_count', style: 'yellow' },
            { title: 'Accepted Requests', key: 'accepted_count', style: 'green' },
            { title: 'Rejected Requests', key: 'declined_count', style: 'red' },
          ],
          cardStyles
        )}
          <Table
            columns={pqr_request_columns}
            dataSource={pqrRequestdataSource}
            pagination={false}
            className="managepqr-container custom-css"
            sticky
            loading={pqrListStatus === 'loading'}
            scroll={{y:300}}
            bordered
          />
        </div>
      </TabPane>
    </Tabs>
  ) : (
    <>
      <div className="flex flex-col w-full gap-4">
      {renderPqrStats(
          [
            { title: 'Total Requests', key: 'total_count', style: 'blue' },
            { title: 'Pending Requests', key: 'pending_count', style: 'yellow' },
            { title: 'Accepted Requests', key: 'accepted_count', style: 'green' },
            { title: 'Rejected Requests', key: 'declined_count', style: 'red' },
          ],
          cardStyles
        )}         
          <Table
            columns={pqr__normal_user_request_columns}
            dataSource={pqrRequestdataSource}
            pagination={false}
            className="managepqr-container custom-css "
            sticky
            loading={pqrListStatus === 'loading'}
            scroll={{y:390}}
            bordered
          />
        </div>

        {/* Add/Edit PQR Modal */}
        <Modal
          title={editingPqr ? 'Edit PQR' : 'Add PQR'}
          centered
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Submit"
          cancelText="Cancel"
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              {isAdmin ? "Update" : "Request"}
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical" name="pqrForm">
            <Form.Item
              name="pqrName"
              label="PQR Name"
              rules={[{ required: true, message: 'Please enter the PQR name!' }]}
            >
              <Input placeholder="Enter PQR name" style={{ width: '100%' }} />
            </Form.Item>
          </Form>
        </Modal>
    </>
  )}
</div>


  );
};

export default ManagePQR;
