import React, { useEffect, useState, useRef } from "react";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Checkbox, Typography, Alert, Tooltip } from "@material-tailwind/react";
import { CiCircleRemove } from "react-icons/ci";
import { AiOutlineDown } from "react-icons/ai"; 
import { useDispatch, useSelector } from "react-redux";
import { addPqr, updatePQRCount, editPQR } from "../../features/Tender/TenderSlice";
import { fetchPqrList } from "../../features/TenderDetail/TenderDetailSlice";


const SelectPqrModal = ({ pqrModalStatus, setPqrModalStatus, tenderId, selectedPqrModal, pqrVal }) => {
  const handleOpen = (value) => setPqrModalStatus(value);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  
  const { pqrListData, pqrListStatus, error } = useSelector(state => state.tenderDetail);
  const { pqr_list, pqrfetchStatus } = useSelector(state => state.tender);

  const [updatedPqrListData, setUpdatedPqrListData] = useState([])

  useEffect(() => {
    if(pqrListData.length > 0){
      updatePQRListData()
    }
  }, [pqrListData])

  const updatePQRListData = () => {
    const data = []
    for(let i=0; i<pqrListData.length; i++){
      const obj = {}
      if(pqrListData?.[i] === "The Bidder should has minimum annual turnover of Rs. 38.58 Lacs for Non MSEs Bidders and Rs. 32.80 Lacs for MSE Bidders during any of the last 3 preceding FY"){
        obj.label = "Minimum Annual Turnover Requirement"
        obj.value = pqrListData?.[i]
      }
      else if(pqrListData?.[i] === "The Bidder should have successfully completed similar work in the past 5 years. Minimum value of work done to be considered is 3 Similar Works - Rs. 19.29 Lacs / Rs. 16.40 Lacs 2 Similar Works - Rs. 25.72 Lacs / Rs. 21.86 Lacs 1 Similar Works - Rs. 32.15 Lacs / Rs. 27.33 Lacs"){
        obj.label = "Similar Works Requirement"
        obj.value = pqrListData?.[i]
      }
      else{
        obj.label = pqrListData?.[i]
        obj.value = pqrListData?.[i]
      }
      data.push(obj)
    }
    setUpdatedPqrListData(data)
  }

  

  useEffect(() => {
    if (!pqrModalStatus) {
      resetValues();
    } else if (pqrModalStatus && pqrListStatus === "idle") {
      dispatch(fetchPqrList()); // Fetching PQR List from the API
    }
  }, [pqrModalStatus, pqrListStatus, dispatch]);

  useEffect(() => {
    if(selectedPqrModal === pqrVal.editPqr && pqrfetchStatus==="succeeded") {
      setSelectedOptions(pqr_list)
    }
  }, [selectedPqrModal, pqrfetchStatus])


  const resetValues = () => {
    setSelectedOptions([]);
    setIsDropdownOpen(false);
    setShowAlert(false);
  };

  const handleSubmit = () => {
    if (selectedOptions.length === 0) {
      setShowAlert(true);
    } else if (selectedPqrModal === pqrVal.editPqr) {
      setIsDropdownOpen(false);
      updatePqrApi();
    } else {
      setIsDropdownOpen(false);
      addPqrApi();
    }
  };

  const handleUpdate = () => {
    if (selectedOptions.length === 0) {
      setShowAlert(true);
    } else {
      setIsDropdownOpen(false);
      updatePqrApi();
    }
  };

  const addPqrApi = async () => {
    try {
      const payload = {
        "doc_id": tenderId,
        "pqr": selectedOptions,
      };
      await dispatch(addPqr(payload));
      dispatch(updatePQRCount({ doc_id: tenderId, new_count: selectedOptions.length, flag: "add_pqr" }));
      setPqrModalStatus(false);
    } catch (error) {
      setShowAlert(true);
      console.error(error);
    }
  };

  const updatePqrApi = async () => {
    try {
      const payload = {
        "doc_id": tenderId,
        "pqrs": selectedOptions,
      };
      await dispatch(editPQR(payload));
      dispatch(updatePQRCount({ doc_id: tenderId, new_count: selectedOptions.length, flag: "edit_pqr" }));
      setPqrModalStatus(false);
    } catch (error) {
      setShowAlert(true);
      console.error(error);
    }
  };

  const handleOptionClick = (option) => {
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
    } 
    else {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
  };

  const selectAll = () => {
    if (selectedOptions.length === updatedPqrListData.length) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(updatedPqrListData.map((item) => item?.value));
    }
  };

  const NoData = () => (
    <div className="h-[40vh] flex items-center justify-center">
      No PQR Selected
    </div>
  );

  const LoadingData = () => (
    <div className="h-[40vh] flex items-center justify-center">
      Loading Data
    </div>
  );

  const FailedData = () => (
    <div className="h-[40vh] flex items-center justify-center">
      Failed to fetch Data
    </div>
  );

  const SelectedPqrList = () => (
    <div className="flex flex-col gap-1 shadow-lg border-gray-700 border overflow-y-scroll h-[46vh] pl-3">
      <div className="w-full flex items-center justify-center py-2 text-black text-lg font-bold">Selected PQRS</div>
      {selectedOptions.map((item, index) => (
        <div key={index} className="flex items-center justify-between mb-4 font-bold">
          <Typography color="gray" className="font-bold">{item}</Typography>
          <Button variant="text" color="red" onClick={() => setSelectedOptions(selectedOptions.filter((option) => option !== item))}>
            <CiCircleRemove size={28} />
          </Button>
        </div>
      ))}
    </div>
  );

  const [tooltipVisible, setTooltipVisible] = useState(false);
  // Custom Tooltip
  

  const CustomTooltip = ({ content }) => (
    <div className="absolute bottom-[90%] z-10 px-2 py-2 text-white bg-black rounded-md shadow-md text-[13px]">
      {content}
    </div>
  );

  console.log(updatedPqrListData)


  return (
    <Dialog open={pqrModalStatus} handler={handleOpen} size="lg" style={{zIndex: 100,  overflow: 'visible' }}>
      {showAlert && (
        <Alert open={showAlert} onClose={() => setShowAlert(false)} color="red">
          Choose at least one PQR
        </Alert>
      )}
      <DialogHeader>
        {selectedPqrModal === pqrVal.editPqr ? "Edit PQR" : "Select PQR"}
      </DialogHeader>

      <DialogBody className="h-[60vh]" style={{zIndex: 100,  overflow: 'visible' }}>
        <div className="relative">
          <div className="flex items-center border p-2 rounded cursor-pointer border-gray-700" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <div className="flex flex-wrap gap-1">
              {selectedOptions.length > 0 ? <span style={{fontWeight:"bold"}}>{selectedOptions.length} selected</span> : <span style={{fontWeight:"bold"}}>Select PQR</span>}
            </div>
            <AiOutlineDown className="ml-auto" />
          </div>
          {
            isDropdownOpen 
          && 
            <div className="border-gray-700 border absolute rounded bg-white divide-y divide-black-700 shadow-lg w-full mt-3 max-h-[48vh] overflow-y-auto">
              <div className="flex items-center p-1 cursor-pointer hover:bg-gray-200 transition duration-200" onClick={selectAll}>
                <Checkbox checked={selectedOptions.length === updatedPqrListData.length} readOnly />
                <span className="ml-2 font-medium">Select all</span>
              </div>
              {updatedPqrListData.map((item, index) => (
                  <div
                    key={index}
                    className={`relative pl-3 py-3 cursor-pointer transition duration-200 font-medium text-black ${
                      selectedOptions.includes(item?.value) ? "bg-[rgb(218,238,219)]" : ""
                    }`}
                    onClick={() => handleOptionClick(item?.value)}
                    onMouseEnter={() => setTooltipVisible(index)}  // Set index of hovered item
                    onMouseLeave={() => setTooltipVisible(null)}  // Reset index when hover ends
                  >
                    {item?.label}
                    {tooltipVisible===index && (
                    <CustomTooltip content={item?.value} />
                  )}
                  </div>
              ))}
            </div>
          }
        </div>
        {pqrListStatus === "loading" ? 
        <LoadingData /> 
        : 
        pqrListStatus === "failed" ? 
        <FailedData /> : 
        selectedOptions.length === 0 ? <NoData /> 
        : 
          // <SelectedPqrList />

          <div className="flex flex-col gap-1 shadow-lg border-gray-700 border overflow-y-scroll h-[46vh] pl-3">
          <div className="w-full flex items-center justify-center py-2 text-black text-lg font-bold">Selected PQRS</div>
          {selectedOptions.map((item, index) => (
            <div key={index} className="flex items-center justify-between mb-4 font-bold">
              <Typography color="gray" className="font-bold">{item}</Typography>
              <Button variant="text" color="red" onClick={() => setSelectedOptions(selectedOptions.filter((option) => option !== item))}>
                <CiCircleRemove size={28} />
              </Button>
            </div>
          ))}
        </div>

        }
      </DialogBody>

      <DialogFooter>
        <Button variant="text" color="red" onClick={() => handleOpen(false)}>
          Cancel
        </Button>
        <Button variant="gradient" color="green" onClick={handleSubmit}>
          {selectedPqrModal === pqrVal.editPqr ? "Update" : "Submit"}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default SelectPqrModal;
