import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import endpoint from "../../api/endpoint"
import { makeRequest } from "../../api/api"
import { tenderStatus, tenderNextStep } from "../../utils/TenderConstants";
import axios from 'axios';


export const fetchAllTenders = createAsyncThunk('tender/fetchTenders',
    async () => {
        try {
            const response = await makeRequest(endpoint.fetchAllTenders, "GET");
            return response.data;
        } catch (error) {
            throw error;
        }
    }
)

export const editPQR = createAsyncThunk('tender/editPQR',
    async (data, { rejectWithValue }) => {
        try {
            const response = await makeRequest(endpoint.editPQR, "PUT", data);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data?.error) {
                return rejectWithValue(error.response.data.error);
            }
            else if (error.response && error.response.data) {
                return rejectWithValue('An error occurred while processing your request');
            }
            else {
                return rejectWithValue('Server error: server not responding');
            }
        }
    }
)

export const fetchPQRList = createAsyncThunk('tenders/tender_pqr/',
    async (docID) => {
        try {
            const response = await makeRequest(`${endpoint.fetchPQRList}/${docID}`, "GET");
            return response?.data?.pqr_list ?? [];
        } catch (error) {
            throw error;
        }
    }

)

export const tenderCount = createAsyncThunk('tender/tenderCount',
    async () => {
        try {
            const response = await makeRequest(endpoint.tenderCount, "GET");
            return response.data;
        } catch (error) {
            throw error;
        }
    }
)

export const manualCreateTender = createAsyncThunk('tender/manualCreateTender',
    async (data, { rejectWithValue }) => {
        try {
            const response = await makeRequest(endpoint.manualCreateTender, "POST", data);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const createTenderV2 = createAsyncThunk('tender/createTenderV2',
    async (data, { rejectWithValue }) => {
        try {
            const response = await makeRequest(endpoint.createTenderV2, "POST", data);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addFirestoreData = createAsyncThunk('tender/addFirestoreData',
    async ({data}, { rejectWithValue }) => {
        try {
            const response = await makeRequest(endpoint.addFirestoreData, "POST", data);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error);
        }
    }
);



export const fileUpload = createAsyncThunk('tender/fileUpload',
    async ({url, data}, { rejectWithValue }) => {
        try {
            const response = await axios.put(url, data, {
                headers: {
                    'Content-Type': data instanceof ArrayBuffer || data instanceof Blob 
                        ? 'application/octet-stream' 
                        : 'multipart/form-data',
                },
            });

            return response.data;  // Return the response data from the server
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);  // Handle errors
        }
    }
);

export const addPqr = createAsyncThunk('tender/addpqr',
    async (data, { rejectWithValue }) => {
        try {
            const response = await makeRequest(endpoint.addPqr, "POST", data);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data?.error) {
                return rejectWithValue(error.response.data.error);
            }
            else if (error.response && error.response.data) {
                return rejectWithValue('An error occurred while processing your request');
            }
            else {
                return rejectWithValue('Server error: server not responding');
            }
        }
    }
)

export const triggerStatusAPI = createAsyncThunk('tender/trigger_status',
    async (data, { rejectWithValue }) => {
        try {
            const response = await makeRequest(endpoint.triggerStatus, "POST", data);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data?.error) {
                return rejectWithValue(error.response.data.error);
            }
            else if (error.response && error.response.data) {
                return rejectWithValue('An error occurred while processing your request');
            }
            else {
                return rejectWithValue('Server error: server not responding');
            }
        }
    }
)

export const triggerGenAI = createAsyncThunk('tender/trigger_genai',
    async (doc_id, { rejectWithValue }) => {
        try {
            const response = await makeRequest(endpoint.triggerGenAI(doc_id), "POST");
            return response.data;
        } catch (error) {
            if (error.response && error.response.data?.error) {
                return rejectWithValue(error.response.data.error);
            }
            else if (error.response && error.response.data) {
                return rejectWithValue('An error occurred while processing your request');
            }
            else {
                return rejectWithValue('Server error: server not responding');
            }
        }
    }
)

export const fetchTenderDetail = createAsyncThunk(
    'tender/fetchTenderDetail',
    async (status, { rejectWithValue }) => {
        try {
            const response = await makeRequest(endpoint.tenderDetails(status), 'GET');
            return { status, data: response.data };
        } catch (error) {
            console.log(error)
            return rejectWithValue({ status, error });
        }
    }
);

export const triggerBot = createAsyncThunk(
    'triggerbot',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await makeRequest(endpoint.triggerBot, 'POST', payload);
            return response.data;
        } catch (error) {
            console.log(error)
            // return rejectWithValue({ status, error });
            return rejectWithValue({ error });
        }
    }
);

export const triggerBotScript = createAsyncThunk(
    'triggerbotscript',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await makeRequest(endpoint.botScript, 'POST', payload);
            return response.data;
        } catch (error) {
            console.log(error)
            // return rejectWithValue({ status, error });
            return rejectWithValue({ error });
        }
    }
);

const tenderSlice = createSlice({
    name: "tender",
    initialState: {
        // fetchAllTenders
        tenderFecthStatus: "idle",
        filesuploaded: false,
        add_firestore_data: [],
        add_firestore_data_response: [],
        tenderArr: [],
        tenderV2: [],
        // tenderCount
        tenderCountStat: {},
        pqr_list: [],
        pqrfetchStatus: "idle",

        pending: {
            data: [],
            loading: false,
            error: null,
        },
        in_progress: {
            data: [],
            loading: false,
            error: null,
        },
        completed: {
            data: [],
            loading: false,
            error: null,
        },
    },
    reducers: {
        updatePQRCount: (state, action) => {
            if (action.payload?.flag === "add_pqr") {
                return {
                    ...state,
                    tenderArr: state.tenderArr.map(element =>
                        (element?.doc_id ?? element?.id) === action.payload?.doc_id
                            ? {
                                ...element,
                                status: tenderStatus.pqrSelected,
                                next_step: tenderNextStep.generateConsolidatedStatement,
                                total_pqrs_selected: action.payload.new_count
                            }
                            : element
                    ),
                    pending: {
                        ...state.pending,
                        data: state.pending.data.map(element =>
                            (element?.doc_id ?? element?.id) === action.payload?.doc_id
                                ? {
                                    ...element,
                                    status: tenderStatus.pqrSelected,
                                    next_step: tenderNextStep.generateConsolidatedStatement,
                                    total_pqrs_selected: action.payload.new_count
                                }
                                : element
                        )
                    }
                };
            }
        
            if (action.payload?.flag === "edit_pqr") {
                return {
                    ...state,
                    tenderArr: state.tenderArr.map(element =>
                        (element?.doc_id ?? element?.id) === action.payload?.doc_id
                            ? {
                                ...element,
                                total_pqrs_selected: action.payload.new_count
                            }
                            : element
                    ),
                    pending: {
                        ...state.pending,
                        data: state.pending.data.map(element =>
                            (element?.doc_id ?? element?.id) === action.payload?.doc_id
                                ? {
                                    ...element,
                                    total_pqrs_selected: action.payload.new_count
                                }
                                : element
                        )
                    }
                };
            }
        
            return state;
        },        
        updateStatus: (state, action) => {
            return {
                ...state,
                // Update tenderArr
                tenderArr: state.tenderArr.map(element =>
                    (element?.doc_id === action.payload?.doc_id || element?.id === action.payload?.doc_id)
                        ? {
                            ...element,
                            status: action.payload.status,
                            next_step: action.payload.next_step
                        }
                        : element
                ),
                // Update pending.data
                pending: {
                    ...state.pending,
                    data: state.pending.data.map(element =>
                        (element?.doc_id === action.payload?.doc_id || element?.id === action.payload?.doc_id)
                            ? {
                                ...element,
                                status: action.payload.status,
                                next_step: action.payload.next_step
                            }
                            : element
                    )
                }
            };
        },
        
        updateTenderCount: (state, action) => {
            if (action.payload?.flag === "new_tender") {
                state.tenderCountStat.total_tenders += 1;
                state.tenderCountStat.pending_tenders += 1;
            }
            else if (action.payload?.flag === "in_progress") {
                state.tenderCountStat.in_progress_tenders += 1;
                state.tenderCountStat.pending_tenders -= 1;
            }
        },

        deletePendingTender: (state, action) => {
            const tenderId = action.payload;
            state.pending.data = state.pending.data.filter(
                element => (element?.doc_id ?? element?.id) !== tenderId
            );
            state.tenderArr = state.tenderArr.filter(
                element => (element?.doc_id ?? element?.id) !== tenderId
            );
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTenders.pending, (state) => {
                state.tenderFecthStatus = 'loading';
                state.tenderArr = []
            })
            .addCase(fetchAllTenders.fulfilled, (state, action) => {
                state.tenderFecthStatus = 'succeeded';
                state.tenderArr = action.payload;
            })
            .addCase(fetchAllTenders.rejected, (state, action) => {
                state.tenderFecthStatus = 'failed';
                state.tenderArr = []
            })
            .addCase(tenderCount.fulfilled, (state, action) => {
                state.tenderCountStat = action.payload;
            })
            .addCase(manualCreateTender.fulfilled, (state, action) => {
                state.tenderArr.unshift(action.payload);
                state.pending.data.unshift(action.payload);
            })
            .addCase(createTenderV2.fulfilled, (state, action) => {
                state.tenderArr.unshift(action.payload);
                state.pending.data.unshift(action.payload);
                state.add_firestore_data = action.payload; 

            })
            
            .addCase(addFirestoreData.fulfilled, (state, action) => {
                state.add_firestore_data_response=action.payload;
            })
            .addCase(fileUpload.pending, (state) => {
                state.filesuploaded = false;
            })
            .addCase(fileUpload.fulfilled, (state,action) => {
                state.filesuploaded = true;
            })
            .addCase(fileUpload.rejected, (state) => {
                state.filesuploaded = false;
            })
            .addCase(fetchPQRList.pending, (state) => {
                state.pqrfetchStatus = "loading";
                state.pqr_list = []
            })
            .addCase(fetchPQRList.fulfilled, (state, action) => {
                state.pqrfetchStatus = "succeeded";
                state.pqr_list = action.payload;

            })
            .addCase(fetchPQRList.rejected, (state) => {
                state.pqrfetchStatus = "failed";
                state.pqr_list = []
            })
            .addCase(editPQR.fulfilled, (state, action) => {
                console.log("Edit PQR fulfilled", action.payload);
            })

            .addCase(fetchTenderDetail.pending, (state, action) => {
                const status = action.meta.arg;
                state[status].loading = true;
                state[status].error = null;
            })
            .addCase(fetchTenderDetail.fulfilled, (state, action) => {
                const { status, data } = action.payload;
            
                let statusKey;
                switch (status) {
                    case 'in_progress':
                        statusKey = 'in_progress_tenders';
                        break;
                    case 'completed':
                        statusKey = 'completed_tenders';
                        break;
                    case 'pending':
                        statusKey = 'pending_tenders';
                        break;
                    default:
                        statusKey = status;  
                }
                // console.log(data, statusKey)
                const updatedData = data[statusKey].map(tender => ({
                    ...tender,
                    total_pqrs_selected: tender.pqr?.length || 0
                }));
            
                state[status].loading = false
                state[status].data = updatedData;
            })
            .addCase(fetchTenderDetail.rejected, (state, action) => {
                const { status, error } = action.payload;
                state[status].loading = false;
                state[status].error = error;
            })
    },
})


// Reducer variables
export const { updatePQRCount, updateStatus, updateTenderCount, deletePendingTender } = tenderSlice.actions;

export default tenderSlice.reducer;