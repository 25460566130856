import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
} from "@material-tailwind/react";
import ReactPDF from 'react-pdf-js';
import { RxCross2 } from "react-icons/rx";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { ReactComponent as DocumentIcon } from "../../Assets/Images/Home/Document.svg";

const ClassifiedDataModal = ({ modalStatus, setModalStatus, data }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [sourceUrl, setSourceUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  // Reset modal state when modalStatus changes
  useEffect(() => {
    const resetModal = () => {
      setActiveIndex(null);
      setSourceUrl(null);
      setFileType(null);
      setPageNumber(1);
      setNumPages(null);
    };
    resetModal();
  }, [modalStatus]);

  const docs = [
    {
      uri: sourceUrl === null ? "https://www.aeee.in/wp-content/uploads/2020/08/Sample-pdf.pdf" : sourceUrl,
      fileType: fileType === null ? "pdf" : fileType,
    },
  ];

  const handleSourceButtonClick = (data, i) => {
    const dataUrl = data?.[1];

    if (activeIndex === i) {
      setActiveIndex(null);
      setSourceUrl(null);
      setPageNumber(1); // Reset page number on close
      setNumPages(null); // Reset numPages on close
    } 
    else {
      if (!dataUrl) {
        alert("No Source Url found!!");
        return;
      }
      if(dataUrl === "NA"){
        alert("No Data Source URL found!!");
        return
      }
      if(!dataUrl.includes("https")){
        alert("No Data Source URL found!!");
        return
      }

      setActiveIndex(i);
      setSourceUrl(dataUrl);

      // Extract file type and page number from URL
      setFileType(extractDataType(dataUrl) || null);

      //  Extract page number from URL
      setPageNumber(extractPageNumberFromUrl(dataUrl) || 1);
    }
  };

  const extractPageNumberFromUrl = (url) => {
    const regex = /#page=(\d+)/;
    const match = url.match(regex);
    return match ? parseInt(match[1], 10) : null;
  };

  const extractDataType = (url) => {
    const regex = /\.([a-zA-Z0-9]+)(?=(\?|#|$))/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const onLoadSuccess = (totalPages) => {
    console.log("Success")
    setNumPages(totalPages);
  };

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= numPages) {
      setPageNumber(pageNumber); // Update page number
    }
  };

  const handleOpen = () => {
    setModalStatus(!modalStatus);
  };

  return (
    <>
      <Dialog size="xxl" open={modalStatus} handler={handleOpen}>
        <DialogHeader className="flex justify-center text-[#8d6641] relative">
          Classified Data
          <RxCross2 onClick={handleOpen} size={30} color="red" className="cursor-pointer absolute right-[2rem]" />
        </DialogHeader>

        <DialogBody className="h-[85vh]">
          <div className="flex gap-10 h-full">
            <div style={{ width: "40%", border: "1px solid #E9EAEC", padding: "6px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", height: "100%" }} className="flex flex-col gap-4">
              <div className="flex-none w-full flex items-center justify-center font-bold text-[20px]">
                Documents Type
              </div>

              <div className="flex justify-start w-full h-full flex-col gap-8 text-[#242325] text-base font-normal overflow-y-scroll py-4">
                {Array.isArray(data) && data?.length > 0 && data?.map((data, index) => (
                  <div key={index} className={`flex w-full justify-between items-center gap-y-8 px-4 gap-x-6 pb-4 py-2 bg-[${activeIndex === index ? "#e4f7d1" : "white"}]`} style={{ borderBottom: "1px solid #E9EAEC" }}>
                    <div className="w-full flex flex-col gap-4">
                      <span className="font-bold text-[17px]">{data?.[3] ?? "NA"}</span>
                      <div className="flex flex-col gap-[1px] text-[16px]">
                        <span className="font-normal">PDF Name: <span className="text-[#212663] font-bold">{data?.[0] ?? "NA"}</span></span>
                        <span>Page No. : <span className="text-[#212663] font-bold">
                          {data?.[2].map((pageNo, index) => (
                            <span key={index}>{pageNo + 1}{index === data?.[2].length - 1 ? "" : ", "}</span>
                          ))}
                        </span></span>
                      </div>
                    </div>
                    <div className="flex flex-col items-center gap-4">
                      <Button onClick={() => handleSourceButtonClick(data, index)} style={{ backgroundColor: `${activeIndex === index ? "green" : "rgb(33 150 243)"}`, padding: "6px 16px" }}>Source</Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div style={{ width: "60%", border: "0.5px solid #E9EAEC", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
              {
              sourceUrl ? 
                fileType === "pdf" 
                ? 
                  <div className="w-full max-h-full overflow-scroll">
                    <div style={{
                      backgroundColor: '#444',
                      padding: '2px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}>
                      <button
                        onClick={() => goToPage(1)}
                        style={{
                          color: '#fff',
                          border: '1.5px solid white',
                          backgroundColor: '#333333',
                          borderRadius: '3px',
                          cursor: 'pointer',
                          padding: '3px 3px',
                        }}
                      >
                        Go to Page 1
                      </button>

                      {numPages && (
                        <div style={{
                          flex: '1',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: '#fff',
                        }}>
                          <p style={{ margin: '0', textAlign: 'center' }}>
                            Page {pageNumber} of {numPages}
                          </p>
                        </div>
                      )}

                      <div style={{ display: 'flex', gap: '5px' }}>
                        <button
                          onClick={() => goToPage(Math.max(pageNumber - 1, 1))}
                          disabled={pageNumber === 1}
                          style={{
                            backgroundColor: '#333333',
                            color: '#fff',
                            border: '1.5px solid white',
                            borderRadius: '3px',
                            cursor: pageNumber === 1 ? 'not-allowed' : 'pointer',
                            padding: '3px 3px',
                          }}
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => goToPage(Math.min(pageNumber + 1, numPages))}
                          disabled={pageNumber === numPages}
                          style={{
                            backgroundColor: '#333333',
                            color: '#fff',
                            border: '1.5px solid white',
                            borderRadius: '3px',
                            cursor: pageNumber === numPages ? 'not-allowed' : 'pointer',
                            padding: '3px 3px',
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>

                    <div style={{
                      margin: '20px auto',
                      width: '100%',
                      backgroundColor: '#fff',
                      padding: '3px',
                    }}>
                      <ReactPDF
                        file={sourceUrl}
                        onDocumentComplete={onLoadSuccess}
                        scale={1.0}
                        page={pageNumber}
                      />
                    </div>
                  </div>
                 : 
                  <div className="flex justify-center items-center w-full max-h-full  overflow-scroll">
                    <DocViewer className="max-h-full" documents={docs} pluginRenderers={DocViewerRenderers} />
                  </div>
                : 
                <div className="w-full h-full">
                  <div className=" flex flex-col justify-center items-center w-full h-full font-bold gap-2">
                    <p className="text-2xl">You can view Source document here</p>
                    <DocumentIcon style={{ width: "8rem" }} />
                  </div>
                </div>
              }
            </div>

          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ClassifiedDataModal;
