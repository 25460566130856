import React from 'react'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';

function Logout({ logoutStatus, setLogoutStatus }) {
  const navigate = useNavigate();

  const logoutHandler = () => {
    // Get the value of is_admin from localStorage
    const isAdmin = localStorage.getItem("is_admin");

    // Remove items from localStorage
    localStorage.removeItem("Authorization");
    localStorage.removeItem("is_admin");

    // Navigate based on the is_admin value
    if (isAdmin === 'true') {
      navigate('/');
    } else {
      navigate('/dept_head');
    }
  };

  return (
    <>
      <Dialog open={logoutStatus} 
        // handler={logoutHandler}
      >
        <DialogHeader className='flex justify-center text-3xl'>Logout</DialogHeader>
        <DialogBody className='flex justify-center text-[red] text-md'>
          Are you sure you want to logout.
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => setLogoutStatus(!logoutStatus)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={logoutHandler}>
            <span>Logout</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default Logout;
