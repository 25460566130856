import React, {} from 'react'
import TenderStats from './TenderStats'
import TenderBreadCrumb from './TenderBreadCrumb';
import TenderDocument from './TenderDocument';
import warningIcon from "../../Assets/Images/View-Tender/warning.svg"

function TenderTopbar() {

  return (
    <div className='w-full flex flex-col gap-5'>
      <TenderStats />
      <TenderBreadCrumb />
      {/* <div className='flex items-center justify-start gap-4 text-[#242325] font-semibold bg-[#E5F7F8] py-3 px-4 shadow-md'>
        <img src={warningIcon} alt="warning" />
          Note: Please refrain from uploading any new bidder documents while the consolidated report is in progress.
      </div> */}
      <TenderDocument />
    </div>
  )
}

export default TenderTopbar