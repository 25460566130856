import React, { useEffect, useState } from 'react'
import { Spinner, Tooltip } from "@material-tailwind/react";
import { BsEye } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { fetchTenderInfo, generateTqs, generateCs } from '../../features/TenderDetail/TenderDetailSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TiPlus } from "react-icons/ti";
import ViewSelectedPqrModal from '../../Components/modals/ViewSelectedPqrModal';
import { ViewDocModal } from '../../Components/modals/ViewDocumentModal';
import UploadDocument from '../../Components/modals/UploadDocument';
import { Button } from "@material-tailwind/react";
import { tenderStatus } from '../../utils/TenderConstants';
import { toast } from 'react-hot-toast';
import TechnicalQueryModal from './TechnicalQueryModal';

const TenderDocument = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { tenderInfo, tenderInfoDetail, tenderStatDetail, is_all_bidders_reviewed, reviewedPqrCsStatus, reviewedPqrCsUrl } = useSelector((state) => state.tenderDetail)
    const [viewModal, setViewModal] = useState(false)
    const [viewDocumentModalStatus, setViewDocumentModalStatus] = useState(false)
    const [selectedCS, setSelectedCS] = useState(null)
    const [selectedCSUrl, setSelectedCsUrl] = useState(null)

    const [uploadDocumentModalStatus, setUploadDocumentModalStatus] = useState(false)
    const docUrl = tenderInfoDetail?.comparative_statement_url || "";

    const [tqModalStatus, setTqModalStatus] = useState(false)

    const [detail, setDetail] = useState([
        { label: "Selected PQRs", count: null, url: null, val: "Selected PQRs", download: false, view: true },
        // { label: "Total Tender Documents", count: null, url: null, val: "Total Tender Documents", download: true, view: false },
        { label: "Combined Statemtent", url: null, val: "Combined Statement", download: true, view: true },
        { label: "Technical Queries", url: null, val: "Technical Queries", download: false, view: true },
        { label: "Comparative Statement", url: null, val: "Comparative Statement", download: false, view: false }
    ])

    // console.log(tenderInfoDetail)
    // console.log(reviewedPqrCsUrl)

    // Update view of "Technical Queries" when all bidders are reviewed
    // useEffect(() => {
    //     if(is_all_bidders_reviewed){
    //         setDetail((prevState) => {
    //             return prevState.map((stat) => {
    //                 switch (stat.val) {
    //                     case "Technical Queries":
    //                         return { ...stat, view: true }
    //                     default:
    //                         return stat
    //                 }
    //             })
    //         })
    //     }
    // }, [is_all_bidders_reviewed])


    useEffect(() => {
        dispatch(fetchTenderInfo(id))
    }, [])

    useEffect(() => {
        if (tenderInfo === "succeeded" || tenderInfo === "failed") {
            updateTenderInfo()
        }
    }, [tenderInfo, tenderInfoDetail])



    const updateTenderInfo = () => {
        setDetail((prevState) => {
            return prevState.map((stat) => {
                switch (stat.val) {
                    case "Selected PQRs":
                        return { ...stat, count: tenderInfoDetail?.num_pqrs ?? "No Data" }
                    case "Total Tender Documents":
                        return { ...stat, count: tenderInfoDetail?.num_tender_docs ?? "No Data", url: tenderInfoDetail?.tender_details_url }
                    default:
                        return stat
                }
            })
        })
    }


    const handleDownload = (data) => {
        // console.log("Data:", data)
        if (tenderStatDetail?.status === tenderStatus.consolidatedStatementGenerating) {
            toast.error("Tender evaluation process is currently in progress. Please wait for the process to complete.");
            return;
        }

        if (data?.val === "Combined Statement") {
            const link = document.createElement("a");
            link.href = docUrl;
            link.download = "combined-statement.csv";
            link.click();
        }
        else if (data?.val === "Comparative Statement") {
            if(reviewedPqrCsUrl === "NA" && tenderInfoDetail?.new_comparative_statement_url === "NA") {
                toast.error("Please complete the review of all bidders before generating the comparative statement.");
                return
            }
            let url = "NA"
            if(reviewedPqrCsUrl !== "NA") {
                url = reviewedPqrCsUrl
            }
            else{
                url = tenderInfoDetail?.new_comparative_statement_url
            }

            const link = document.createElement("a");
            link.href = url;
            link.download = "comparative-statement.csv";
            link.click();
        }
        else if(data?.val === "Total Tender Documents"){
            const url = data?.url
            console.log(url)
            const link = document.createElement("a");
            link.href = `https://iocl-backend-j7no7yv4kq-el.a.run.app/tenders/download-folder/?url=${url}`;
            link.click();
        }
    };

    const viewPqrHandler = (elem) => {
        if (elem?.val === "Selected PQRs") {
            setViewModal(true);
            return
        }

        if(tenderStatDetail?.status === tenderStatus.consolidatedStatementGenerating) {
            toast.error("Tender evaluation process is currently in progress. Please wait for the process to complete.");
            return;
        }

        if(elem?.val === "Technical Queries" && !is_all_bidders_reviewed){
            toast.error("Please complete the review of all bidders before generating the technical queries.");
            return
        }



        if(elem?.val === "Technical Queries"){
            dispatch(generateTqs({ id}))
            setTqModalStatus(true)
        }
        else if (elem?.val === "Combined Statement") {
            if(tenderInfoDetail?.comparative_statement_url?.length === 0) {
                toast.error("Please wait for the combined statement to be generated.");
                return
            }
            let url = tenderInfoDetail?.comparative_statement_url ?? ""
            
            setSelectedCS(elem?.val)
            setSelectedCsUrl(url)
            setViewDocumentModalStatus(true);
        } 
        else if(elem?.val === "Comparative Statement"){
            if(reviewedPqrCsUrl === "NA" && tenderInfoDetail?.new_comparative_statement_url === "NA") {
                toast.error("Please complete the review of all bidders before generating the comparative statement.");
                return
            }
            let url = "NA"
            if(reviewedPqrCsUrl !== "NA") {
                url = reviewedPqrCsUrl
            }
            else{
                url = tenderInfoDetail?.new_comparative_statement_url
            }

            setSelectedCS(elem?.val)
            setSelectedCsUrl(url)
            setViewDocumentModalStatus(true);
        }
        else if(elem?.val === "Selected PQRs") {
            setViewModal(true);

        }
    };

    // console.log(selectedCSUrl, selectedCS)

    

    const formatProcessingTime = (seconds) => {
        if (seconds < 3600) {
          // If less than 1 hour, return minutes and rounded seconds
          let minutes = Math.floor(seconds / 60);
          let secs = Math.round(seconds % 60); // round to nearest integer
          return `${minutes} minutes ${secs} seconds`;
        } else {
          // If 1 hour or more, return hour minutes and rounded seconds format
          let hours = Math.floor(seconds / 3600);
          let minutes = Math.floor((seconds % 3600) / 60);
          let secs = Math.round(seconds % 60); // round to nearest integer
          return `${hours} hour ${minutes.toString()} minutes ${secs.toString()} seconds`;
        }
    }

    
    // Generate Comparative Statement
    const comparativeStatementClickHandler = () => {
        if(!is_all_bidders_reviewed) {
            toast.error("Please complete the review of all bidders before generating the comparative statement.");
            return;
        }
        // API Call
        dispatch(generateCs({ id }))
    }

    // Update View Icon of new Comparative Statement
    useEffect(() => {
        const updateViewIcon = () => {
            setDetail((prevState) => {
                return prevState.map((stat) => {
                    switch (stat.val) {
                        case "Comparative Statement":
                            return { ...stat, view: true, download: true }
                        default:
                            return stat
                    }
                })
            })
        }

        if(tenderInfo==="succeeded" && tenderInfoDetail?.new_comparative_statement_url !== "NA") {
            updateViewIcon()
        }
        if(reviewedPqrCsUrl !== "NA"){
            updateViewIcon()
        }
    }, [tenderInfo, reviewedPqrCsStatus, reviewedPqrCsUrl, tenderInfoDetail])
    


    return (
        //<div className='flex gap-4 w-full'>
         <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 w-full'>

            <ViewSelectedPqrModal viewPqrModalStatus={viewModal} setViewPqrModalStatus={setViewModal} pqrList={tenderInfoDetail?.pqr_list ?? []}/>
            <UploadDocument uploadDocumentModalStatus={uploadDocumentModalStatus} setUploadDocumentModalStatus={setUploadDocumentModalStatus} doctext={"Bidder Document"} />
            <ViewDocModal viewDocumentModalStatus={viewDocumentModalStatus} setViewDocumentModalStatus={setViewDocumentModalStatus} selectedCS={selectedCS} selectedCSUrl={selectedCSUrl}/>

            <TechnicalQueryModal modalStatus={tqModalStatus} setModalStatus={setTqModalStatus} />
            
            {/* <div className="flex flex-row items-center justify-between gap-4 w-full"> */}

                {/* <div className="flex flex-col justify-between gap-4 bg-[#FFFFFF] text-[black] border rounded-lg px-4 py-2 shadow-md">
                    <div className='text-[#242325] text-[15px] font-bold h-full'>Combined Statement</div>
                    <div className={`flex justify-end cursor-pointer`}>
                        <BsEye size={18} color={"#33363F"} onClick={() => viewPqrHandler("Combined Statement")}/>
                    </div>
                </div> */}

                {/* <div className='flex flex-row gap-6'> */}
                {
                    detail.map((elem, i) => (
                        <div key={i} className="flex flex-col justify-between gap-2 bg-[#FFFFFF] border rounded-lg px-4 py-2 shadow-md">
                            {
                            (tenderStatDetail.status === "Consolidated Statement Generating" && elem?.val !== "Selected PQRs")
                            ? 
                                <>
                                    <Tooltip content="Values will be available after tender evaluation.">
                                        <div className='text-[#808080] text-[15px] font-bold'>
                                            <div>{elem.label}</div>
                                            <span className='text-[12px]'>Processing...</span>
                                        </div>
                                    </Tooltip>
                                </>
                            : 
                                <>
                                    <div className='text-[#242325] text-[15px] font-bold'>{elem.label}</div>
                                    {elem.val === "Combined Statement" && 
                                        <p className='text-[12px]'>
                                            Processing Time Taken:
                                            <p>{tenderInfoDetail?.processing_time_comparative_statement ? formatProcessingTime(tenderInfoDetail?.processing_time_comparative_statement) : "NA"}</p>
                                        </p>
                                    }
                                    
                                </>
                            }
                            
                            <div className='flex items-center justify-between'>
                                <span className='text-[#126568] font-bold text-xl'>{elem.count}</span>
                                <div className='flex gap-4 items-end'>
                                    {
                                        elem?.download && (
                                            
                                            <div
                                                className={`cursor-pointer ${tenderStatDetail.status === "Consolidated Statement Generating" && (elem.label === "Comparative Statement" || elem.label === "Technical Queries") ? 'cursor-no-drop' : ''}`}
                                                onClick={() => handleDownload(elem)}
                                            >   
                                                <FiDownload size={18} color={tenderStatDetail.status === "Consolidated Statement Generating" && (elem.label === "Comparative Statement" || elem.label === "Technical Queries") ? "#808080" : "#33363F"} />
                                            </div>
                                            
                                        
                                        )
                                    }
                                    {
                                        elem?.view && (
                                            <div
                                                className={`cursor-pointer ${tenderStatDetail.status === "Consolidated Statement Generating" && (elem.label === "Comparative Statement" || elem.label === "Technical Queries") ? 'cursor-no-drop' : ''}`}
                                                onClick={() => viewPqrHandler(elem)}
                                            >
                                                <BsEye size={18} color={tenderStatDetail.status === "Consolidated Statement Generating" && (elem.label === "Comparative Statement" || elem.label === "Technical Queries") ? "#808080" :"#33363F"} />
                                            </div>
                                        )
                                    }
                                    {
                                        (elem?.val === "Comparative Statement" && (tenderInfoDetail?.new_comparative_statement_url === "NA" && reviewedPqrCsUrl === "NA")) &&
                                        <Button loading={reviewedPqrCsStatus==="loading" ? true : false} disabled={tenderStatDetail?.status === tenderStatus.consolidatedStatementGenerating ? true : false} size="sm" color="green" onClick={() => comparativeStatementClickHandler()}>
                                            Generate
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
                {/* </div> */}
            {/* </div> */}

            {/* <div onClick={() => setUploadDocumentModalStatus(true)} className='flex items-center justify-between gap-2 text-base bg-[#E5F7F8] border rounded-lg px-4 py-2 shadow-md cursor-pointer'>
                <TiPlus size={28} />
                <div className='text-center'>Upload Bidder Documents</div>
            </div> */}
        </div>
    )
}

export default TenderDocument