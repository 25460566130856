import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const hostName= 'https://iocl-backend-j7no7yv4kq-el.a.run.app';

export const fetchTenderStatusCount = createAsyncThunk(
  'tenders/fetchTenderStatusCount', 
  async ({ start_date, end_date }, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem('Authorization');
        const response = await axios.get(
        `${hostName}/tenders/tender-status-count/?start_date=${start_date}&end_date=${end_date}`,{
            headers: {
                Authorization: `${token}`,
              },
            });
            return response.data;
        }catch (error){
          return rejectWithValue(error?.message ?? 'Something went wrong');
        }
      }
      );
const tenderStatusSlice = createSlice({
    name: 'tenderStatus',
    initialState: {
      tenderStatusCount: [],
      loading: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchTenderStatusCount.pending, (state) => {
          state.loading = true;
          state.error = null; 
        })
        .addCase(fetchTenderStatusCount.fulfilled, (state, action) => {
          state.loading = false;
          state.tenderStatusCount = action.payload;
          // console.log(action.payload);
        })
        .addCase(fetchTenderStatusCount.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload; 
        });
    },
  });
  
  export default tenderStatusSlice.reducer;
