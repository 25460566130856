import { useEffect, useState } from "react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Typography, Button, Chip, Tooltip } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import SelectPqrModal from "../../Components/modals/SelectPqrModal";
import ViewSelectedPqrModal from "../../Components/modals/ViewSelectedPqrModal";
import GCSWarningModal from "../../Components/modals/GCSWarningModal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import {fetchAllTenders,fetchPQRList,triggerStatusAPI,updateStatus,updatePQRCount} from "../../features/Tender/TenderSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FaPencil } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { tenderStatus, tenderNextStep } from "../../utils/TenderConstants";
import { setActiveTenderType } from "../../features/TenderDetail/TenderDetailSlice";
const TABLE_HEAD = [
  "Tender Name",
  "Tender Number",
  "Total Bidders",
  "Total PQRs Selected",
  "Doc Upload Mode",
  "Created At",
  "Created By",
  "Status",
  "Next Step",
];

export default function Table({data, status, type}) {
  let tableHeadCopy = [...TABLE_HEAD];
  const [userIsAdmin, setUserIsAdmin] = useState(false)
  const [userID, setUserID] = useState(0)
  const [isUserCreatedTender, setIsUserCreatedTender] = useState(false)
  // console.log("status: ", status)

  useEffect(() => {
    const value = localStorage.getItem("is_admin")
    if(value === "true"){
      setUserIsAdmin(true)
    }
    else{
      setUserIsAdmin(false)
    }
    setUserID(localStorage.getItem("id") ? +localStorage.getItem("id") : 0)
  }, [])
  // console.log("userID: ", userID)

  // if(type === "Completed Tenders Evaluation" || type === "My Tender Status"){
  //   tableHeadCopy.splice(3,0,"Processing Time Taken");
  // }
  tableHeadCopy.splice(3,0,"Processing Time Taken");

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(()=>{
    // dispatch(setActiveTenderType(''));
    if(location.pathname === "/archived"){
      type = "Archived";
    }
  })

  const {activeTenderType} = useSelector((state)=>state.tenderDetail);

  const [pqrModalStatus, setPqrModalStatus] = useState(false);
  const [selectedPqrModal, setSelectedPqrModal] = useState("");
  const [viewPqrModalStatus, setViewPqrModalStatus] = useState(false);
  const [warningModalStatus, setSelectWarningModalStatus] = useState(false);
  const [tenderId, setTenderId] = useState("");
  const [currentData, setCurrentData] = useState(null);
  const navigate = useNavigate();

  const pqrVal = { newPqr: "newPqr", editPqr: "editPqr" };

  useEffect(()=>{
    dispatch(setActiveTenderType(type));
  },[type])



  const nextStepHandler = (data) => {
    if (data?.next_step === tenderNextStep.startPqrSelection) {
      setTenderId(data?.doc_id ?? data?.id);
      setPqrModalStatus(true);
      setSelectedPqrModal(pqrVal.newPqr);
    } 
    else if (
      data?.next_step === tenderNextStep.generateConsolidatedStatement
    ) {
      setCurrentData(data); // Store the current data to be used in the warning modal
      setSelectWarningModalStatus(true);
    } 
    else if (data?.next_step === tenderNextStep.viewProgress) {
      // console.log("next_type:", data.next_step);   // Check if data.next_type exists
      navigate(`/view_detail/${data?.id}`, { state: { next_step: data.next_step } });
    } 
    else if (data?.next_step === tenderNextStep.viewProcess) {
      navigate(`/view_detail/${data?.id}`);
    }
  };

  const [statementLoading, setStatementLoading] = useState("");

  const viewPQRHandler = (docID, status) => {
    console.log("View PQR")
    setTenderId(docID);
    if (status === "PQRs Selected") {
      setSelectedPqrModal(pqrVal.editPqr);
      setPqrModalStatus(true);
      dispatch(fetchPQRList(docID));
    } 
    else if (status === tenderStatus.consolidatedStatementGenerating || status === tenderStatus.consolidatedStatementGenerated) {
      setViewPqrModalStatus(true);
      dispatch(fetchPQRList(docID));
    }
  };

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      // second: "numeric",
      // hour12: false,
      hour12: true,
    };
    return date.toLocaleString(undefined, options);
  };

  const NoData = () => {
    return (
      <tbody>
        <tr>
          <td colSpan={tableHeadCopy.length} className="p-4 text-center">
            No Data
          </td>
        </tr>
      </tbody>
    );
  };

  const LoadingData = () => {
    // Dummy Array for loading
    const arrayLength = 10;
    const dummyArray = Array.from({ length: arrayLength }, (_, index) => ({
      id: index + 1,
    }));

    return (
      <tbody>
        {dummyArray.map((_, index) => (
          <tr key={index}>
            <td className="p-4 border-b border-blue-gray-50">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  <Skeleton height={25} />
                </Typography>
              </div>
            </td>
            <td className="p-4 border-b border-blue-gray-50">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  <Skeleton height={25} />
                </Typography>
              </div>
            </td>
            <td className="p-4 border-b border-blue-gray-50">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  <Skeleton height={25} />
                </Typography>
              </div>
            </td>
            <td className="p-4 border-b border-blue-gray-50">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  <Skeleton height={25} />
                </Typography>
              </div>
            </td>
            <td className="p-4 border-b border-blue-gray-50">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  <Skeleton height={25} />
                </Typography>
              </div>
            </td>
            <td className="p-4 border-b border-blue-gray-50">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  <Skeleton height={25} />
                </Typography>
              </div>
            </td>
            <td className="p-4 border-b border-blue-gray-50">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  <Skeleton height={25} />
                </Typography>
              </div>
            </td>
            <td className="p-4 border-b border-blue-gray-50">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  <Skeleton height={25} />
                </Typography>
              </div>
            </td>
            <td className="p-4 border-b border-blue-gray-50">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  <Skeleton height={25} />
                </Typography>
              </div>
            </td>
            <td className="p-4 border-b border-blue-gray-50">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  <Skeleton height={25} />
                </Typography>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  const data_status = {
    [tenderStatus.pqrSelected]: { icon: <FaPencil size={16} />, text: "Edit" },
    [tenderStatus.consolidatedStatementGenerating]: {
      icon: <FaEye />,
      text: "View",
    },
    [tenderStatus.consolidatedStatementGenerated]: {
      icon: <FaEye />,
      text: "View",
    }
  };

  const formatTime = (seconds) => {
    if (seconds < 3600) {
      // If less than 1 hour, return minutes and rounded seconds
      let minutes = Math.floor(seconds / 60);
      let secs = Math.round(seconds % 60); // round to nearest integer
      return `${minutes} minutes ${secs} seconds`;
    } else {
      // If 1 hour or more, return hour minutes and rounded seconds format
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      let secs = Math.round(seconds % 60); // round to nearest integer
      return `${hours} hour ${minutes.toString()} minutes ${secs.toString()} seconds`;
    }
  }


  const Data = ({item, index}) => {
    setIsUserCreatedTender(true)
    const isLast = index === item.length - 1;
    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

    return (
                    <tr key={index}>
                      <td className={`sticky left-0 bg-white z-10 ${classes}`}>
                        <div className="flex flex-col items-start gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal capitalize w-[155px]"
                          >
                            {item?.tender_name ?? "NA"}
                          </Typography>
                        </div>
                      </td>
                      <td className={`sticky left-[155px] bg-white z-10 ${classes}`}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal capitalize"
                        >
                          {item?.tender_number?.length > 0 ? item?.tender_number : "NA"}
                        </Typography>
                      </td>
                      <td className={`${classes} text-center`}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.total_bidders ?? item?.num_bidders ?? "NA"}
                        </Typography>
                      </td>
                      <td className={`${classes} text-center`}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {(item?.tender_processing_time && item?.tender_processing_time!=="NA") ? formatTime(item?.tender_processing_time)  : "NA"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center justify-center gap-8">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item?.total_pqrs_selected > 0 ? item.total_pqrs_selected : "NA"}
                          </Typography>

                          <Tooltip content={data_status[item?.status]?.text}>
                            <div
                              className="cursor-pointer"
                              onClick={() =>
                                viewPQRHandler(item?.doc_id ?? item?.id, item.status)
                              }
                            >
                              {data_status[item?.status]?.icon}
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                      <td className={`${classes} text-center`}>
                        <div className="flex flex-col items-center gap-3">
                          {item?.source ? (
                            <Chip
                              color="purple"
                              variant="ghost"
                              value={item?.source}
                              className="normal-case capitalize font-normal text-sm"
                            />
                          ) : (
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal capitalize"
                            >
                              NA
                            </Typography>
                          )}
                        </div>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {formatDateTime(item?.created_at) ?? "NA"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.submitted_by?.trim() ?? "NA"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <div className="flex flex-col items-start gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item?.status === "Documents Downloaded"
                              ? "Documents Uploaded"
                              : item?.status ?? "NA"}
                          </Typography>
                        </div>
                      </td>
                      <td className={`sticky right-0 bg-white z-10 ${classes} w-[250px]`}>
                        <div className="flex flex-col items-start gap-3 w-full">
                          <Button
                            className="w-full"
                            onClick={() => nextStepHandler(item)}
                            color="blue"
                            loading={statementLoading === (item?.doc_id ?? item?.id)}
                          >
                            {item?.next_step ?? "NA"}
                          </Button>
                        </div>
                      </td>
                    </tr>
    );
  }


  return (
      <div className="border flex flex-col border-blue-gray-100 w-full h-full">
        <SelectPqrModal
          selectedPqrModal={selectedPqrModal}
          pqrModalStatus={pqrModalStatus}
          setPqrModalStatus={setPqrModalStatus}
          pqrVal={pqrVal}
          tenderId={tenderId}
        />
        <ViewSelectedPqrModal
          viewPqrModalStatus={viewPqrModalStatus}
          setViewPqrModalStatus={setViewPqrModalStatus}
        />
        <GCSWarningModal
          warningModalStatus={warningModalStatus}
          setSelectWarningModalStatus={setSelectWarningModalStatus}
          tenderData={currentData}
          setStatementLoading={setStatementLoading}
        />

      <div className="relative overflow-x-auto h-full tender-table">
        <table className="w-full min-w-max table-auto text-left bg-white">
          <thead className="sticky top-0 bg-gray-50 dark:bg-neutral-700 z-20">
            <tr>
              {tableHeadCopy.map((head, index) => (
                <th
                  key={head}
                  className={`${
                    index === 0 ? 'sticky left-0 z-20 bg-gray-50 dark:bg-neutral-700' : ''} ${
                    index === 1 ? 'sticky left-[155px] z-20 bg-gray-50 dark:bg-neutral-700' : ''} ${
                    index === tableHeadCopy.length - 1 ? 'sticky right-0 z-20 bg-gray-50 dark:bg-neutral-700' : ''
                  } cursor-pointer border-b border-blue-gray-100 p-4 transition-colors hover:bg-blue-gray-50`}
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    {head}{" "}
                    {index !== tableHeadCopy.length - 1 && (
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    )}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>

          {
            (status === "loading" || status===true) ? 
              <LoadingData />
            : 
            data?.length > 0 ? 
              <tbody className="overflow-y-auto">
                {data?.map((item, index) => {
                  if(userIsAdmin){
                    return <Data key={index} item={item} index={index}/>
                  }
                  else{
                    return (item?.created_by === userID ? <Data key={index} item={item} index={index}/> : null) ?? null
                  }
                })
                }
              </tbody>
            :
            // <NoData />
            // null
            <tbody>
              <tr>
                <td colSpan={tableHeadCopy.length} className="p-4 text-center">
                  Loading...
                </td>
              </tr>
            </tbody>

          }

          {/* {
            !isUserCreatedTender &&
            <NoData />
          } */}
        </table>
      </div>
    </div>
  );
}
