import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "../../api/endpoint";
import { makeRequest } from "../../api/api";
import { toast } from 'react-hot-toast';
import { pqrList } from "./constant";

// Fetch Tender Stats
export const fetchTenderStat = createAsyncThunk(
  "tender/fetchTenderStat",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.tenderStat(id), "GET");
      return response.data;
    } catch (error) {
      toast.error(error.error ?? "Something went wrong");
      return rejectWithValue(error);
    }
  }
);

// Progress Bar & Tender Status
export const tenderProgressbarStatus = createAsyncThunk(
  "tender/fetchProgressbar/status",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.tenderStat(id), "GET");
      return response.data;
    } catch (error) {
      toast.error(error.error ?? "Something went wrong");
      return rejectWithValue(error);
    }
  }
);

// Fetch Bidder Detail
export const fetchBidderDetail = createAsyncThunk(
  "tender/fetchBidderDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.bidderDetail(id), "GET");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to load bidder details");
      return rejectWithValue(error);
    }
  }
);

// Upload Tender Document
export const uploadTenderDocument = createAsyncThunk(
  "tender/uploadTenderDocument",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.uploadTenderDocument(id), "POST", data);
      toast.success("Tender document uploaded successfully");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to upload tender document");
      return rejectWithValue(error);
    }
  }
);

// Upload Existing Bidder Document
export const uploadExistingBidderDoc = createAsyncThunk(
  "tender/uploadBidderDocument",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.uploadExistingBidderDocument(id), "PUT", data);
      toast.success("Bidder document updated successfully");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to upload bidder document");
      return rejectWithValue(error);
    }
  }
);

// Fetch PQR List
export const fetchPqrList = createAsyncThunk(
  "tender/fetchPqrList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.pqrList, "GET");
      return {
        pqrNames: response.data.pqr_names, // Getting PQR names
        count: response.data.count,         // Getting the count
      };
    } catch (error) {
      toast.error(error.message ?? "Failed to load PQR list");
      return rejectWithValue(error);
    }
  }
);

// Upload New Bidder Document
export const uploadNewBidderDoc = createAsyncThunk(
  "tender/uploadBidderDocument",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.uploadNewBidderDocument(id), "POST", data);
      toast.success("New bidder document uploaded successfully");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to upload new bidder document");
      return rejectWithValue(error);
    }
  }
);

// Fetch Tender Info
export const fetchTenderInfo = createAsyncThunk(
  "tender/fetchTenderInfo",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.tenderInfo(id), "GET");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to load tender information");
      return rejectWithValue(error);
    }
  }
);

// Download Folder
export const downloadFolder = createAsyncThunk(
  "tender/downloadfolder",
  async (url, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.downloadFolder(url), "GET");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to download folder");
      return rejectWithValue(error);
    }
  }
);


// Accept PQR
export const acceptPQR = createAsyncThunk(
  "tender/acceptPQR",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.acceptPqr(id), "POST", data);
      toast.success("PQR value accepted successfully!!");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to accept PQR value.");
      return rejectWithValue(error);
    }
  }
);

// Decline PQR
export const declinePQR = createAsyncThunk(
  "tender/declinePQR",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.declinePqr(id), "POST", data);
      toast.success("PQR value declined successfully!!");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to decline PQR value.");
      return rejectWithValue(error);
    }
  }
);

// Edit PQR
export const editPQR = createAsyncThunk(
  "tender/editPQR",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.editPqr(id), "PUT", data);
      toast.success("PQR value updated successfully!!");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to update PQR value.");
      return rejectWithValue(error);
    }
  }
);

// Accept Bidder all PQR
export const acceptBidderAllPqr = createAsyncThunk(
  "tender/acceptBidderAllPqr",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.accept_bidder_all_pqrs(id), "POST", data);
      toast.success("Bidder PQR value updated successfully!!");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to update Bidder PQR value.");
      return rejectWithValue(error);
    }
  }
);

// Decline Bidder all PQR
export const declineBidderAllPqr = createAsyncThunk(
  "tender/declineBidderAllPqr",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.decline_bidder_all_pqrs(id), "POST", data);
      toast.success("Bidder PQR value updated successfully!!");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to update Bidder PQR value.");
      return rejectWithValue(error);
    }
  }
);

// Accept all Bidders all PQR 
export const acceptAllBiddersAllPqr = createAsyncThunk(
  "tender/acceptAllBiddersAllPqr",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.accept_all_bidders_all_pqrs(id), "POST");
      toast.success("Bidder PQR value updated successfully!!");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to update Bidder PQR value.");
      return rejectWithValue(error);
    }
  }
);

// Decline all Bidders all PQR 
export const declineAllBiddersAllPqr = createAsyncThunk(
  "tender/declineAllBiddersAllPqr",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.decline_all_bidders_all_pqrs(id), "POST");
      toast.success("Bidder PQR value updated successfully!!");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to update Bidder PQR value.");
      return rejectWithValue(error);
    }
  }
);

// Decline all Bidders all PQR 
export const generateTqs = createAsyncThunk(
  "tender/generateTqs",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.generate_tqs(id), "POST");
      toast.success("Technical Queries generated successfully!!");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to generate Technical Queries.");
      return rejectWithValue(error);
    }
  }
);

// Generate Comparative Statement
export const generateCs = createAsyncThunk(
  "tender/generateCs",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.generate_cs(id), "GET");
      toast.success("Comparative Statement generated successfully!!");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to generate Comparative Statement.");
      return rejectWithValue(error);
    }
  }
);

// Send Email
export const sendBiddersTqsEmail = createAsyncThunk(
  "tender/sendBiddersTqsEmail",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.send_bidder_tqs_email(id), "POST", data);
      toast.success("TQ email sent successfully!!");
      return response.data;
    } catch (error) {
      toast.error(error.message ?? "Failed to send TQ email.");
      return rejectWithValue(error);
    }
  }
);




// Initial state
const initialState = {
  tenderStat: "idle",
  tenderStatDetail: {},
  bidderDetailStatus: "idle",
  bidderDetail: [],
  callBidderDetailAfterAllBiddersReviewed: false,
  is_all_bidders_reviewed: false,
  tenderInfo: "idle",
  tenderInfoDetail: {},
  activeTenderType: '',
  pqrListStatus: "idle",
  pqrListData: [],
  generateTQStatus: "idle",
  generateTQData: {},
  comparativeStatementStatus: "idle",
  comparativeStatementData: {},
  reviewedPqrCsStatus: "idle",
  reviewedPqrCsUrl: "NA",
  biddersTqsEmailStatus: "idle",
};


// Slice
const tenderDetailSlice = createSlice({
  name: "tenderDetail",
  initialState,
  reducers: {
    updatecallBidderDetailAfterAllBiddersReviewed: (state, action) => {
      state.callBidderDetailAfterAllBiddersReviewed = action.payload.val
    },
    updateTenderDocCount: (state, action) => {
      state.tenderStatDetail.total_tender_documents = action.payload;
      state.tenderInfoDetail.num_tender_docs = action.payload;
    },
    updateExistingBidderCount: (state, action) => {
      const { id, num_docs } = action.payload;
      const bidder = state.bidderDetail.find(bidder => bidder.id === id);
      if (bidder) {
        bidder.num_docs = num_docs;
      }
    },
    updateNewBidder: (state, action) => {
      state.bidderDetail.push(action.payload);
      if (state.tenderStatDetail.total_bidder) {
        state.tenderStatDetail.total_bidder += 1;
      }
    },
    setActiveTenderType: (state, action) => {
      state.activeTenderType = action.payload;
    },
    updatePqrValue: (state, action) => {
      const { bidderId, pqrId, pqrKey, pqrValue } = action.payload;
      const bidder = state.bidderDetail?.find(bidder => bidder.id === bidderId);
      if (!bidder) {
        console.error(`No bidder found with ID: ${bidderId}`);
        return; 
      }
      const pqr = bidder?.reviewed_pqrs?.[pqrId];
      if (!pqr) {
        console.error(`No PQR found for ID: ${pqrId} in bidder: ${bidderId}`);
        return;
      }
      pqr[pqrKey] = pqrValue
    },
    updatePqrStatus: (state, action) => {
      const { bidderId, pqrId, pqrStatus } = action.payload;
      const bidder = state.bidderDetail?.find(bidder => bidder.id === bidderId);
      if (!bidder) {
        console.error(`No bidder found with ID: ${bidderId}`);
        return; 
      }

      const pqr = bidder?.reviewed_pqrs?.[pqrId];
      if (!pqr) {
        console.error(`No PQR found for ID: ${pqrId} in bidder: ${bidderId}`);
        return;
      }
      pqr["is_accepted"] = pqrStatus
      
      // Updating PQRs reviwed boolean value if all pqrs reviewed
      let count = 0
      for(let i=0; i<bidder?.reviewed_pqrs?.length; i++) {
        const validEntry = Object.entries(bidder?.reviewed_pqrs[i]).find(
          ([key]) => key !== "is_accepted" && key !== "source" && key !== "comment"
        );
        const [key, value] = validEntry;

        if(bidder?.reviewed_pqrs[i]?.is_accepted === null && value !== "NA" && value !== "Not Found") {
          console.log("bidder?.reviewed_pqrs[i]: ", bidder?.reviewed_pqrs[i])
          count = 1
          break
        }
      }
      if(count === 0){
        bidder.pqrs_reviewed = true
      }

      // Updating PQRs reviwed boolean value for all Bidders if all pqrs reviewed
      let flag = 0
      // Check whether other pqrs are not accepted
      for(let i=0; i<state.bidderDetail?.length; i++) {
        if(state.bidderDetail[i]?.pqrs_reviewed === false) {
          flag = 1
          break
        }
      }
      if(flag === 0){
        state.is_all_bidders_reviewed = true
        state.callBidderDetailAfterAllBiddersReviewed = true
      }
    },
    markSingleBidderPqrsAsAccepted: (state, action) => {
      const { bidderId } = action.payload;
      const bidder = state.bidderDetail?.find(bidder => bidder.id === bidderId);
      if (!bidder || !Array.isArray(bidder.reviewed_pqrs)) {
        console.error(`Bidder with ID ${bidderId} not found or has invalid reviewed_pqrs.`);
        return;
      }

      // Create a shallow copy of reviewed_pqrs
      bidder.reviewed_pqrs = bidder.reviewed_pqrs.map(pqr => {
        const validEntry = Object.entries(pqr).find(
          ([key]) => key !== "is_accepted" && key !== "source" && key !== "comment"
        );
        const [key, value] = validEntry;

        if(value === "NA" || value === "Not Found") {
          return { ...pqr, is_accepted: false };
        }

        if (pqr?.is_accepted === null) {
          return { ...pqr, is_accepted: true };
        }
        return pqr;
      });

      // Mark pqrs_reviewed as true
      bidder.pqrs_reviewed = true;


      let count = 0
      // Check whether other pqrs are not accepted
      for(let i=0; i<state.bidderDetail?.length; i++) {
        if(state.bidderDetail[i]?.pqrs_reviewed === false) {
          count = 1
          break
        }
      }
      if(count === 0){
        state.is_all_bidders_reviewed = true
        state.callBidderDetailAfterAllBiddersReviewed = true
      }
      
    },
    markSingleBidderPqrsAsDeclined: (state, action) => {
      const { bidderId } = action.payload;
      const bidder = state.bidderDetail?.find(bidder => bidder.id === bidderId);
      if (!bidder || !Array.isArray(bidder.reviewed_pqrs)) {
        console.error(`Bidder with ID ${bidderId} not found or has invalid reviewed_pqrs.`);
        return;
      }

      // Create a shallow copy of reviewed_pqrs
      bidder.reviewed_pqrs = bidder.reviewed_pqrs.map(pqr => {
        const validEntry = Object.entries(pqr).find(
          ([key]) => key !== "is_accepted" && key !== "source" && key !== "comment"
        );
        const [key, value] = validEntry;

        if(value === "NA" || value === "Not Found") {
          return { ...pqr, is_accepted: false };
        }

        if (pqr?.is_accepted === null) {
          return { ...pqr, is_accepted: false };
        }
        return pqr;
      });
      
      // Mark pqrs_reviewed as true
      bidder.pqrs_reviewed = true;

      let count = 0
      // Check whether other pqrs are not accepted
      for(let i=0; i<state.bidderDetail?.length; i++) {
        if(state.bidderDetail[i]?.pqrs_reviewed === false) {
          count = 1
          break
        }
      }
      if(count === 0){
        state.is_all_bidders_reviewed = true
        state.callBidderDetailAfterAllBiddersReviewed = true
      }
    },

    markAllBidderPqrsAsAccepted: (state, action) => {
      const unreviewedBidders = state.bidderDetail?.filter(bidder => bidder.pqrs_reviewed === false);

      if(unreviewedBidders && unreviewedBidders.length > 0){
        unreviewedBidders.forEach(bidder => {
          // Update reviewed_pqrs
          bidder.reviewed_pqrs = bidder.reviewed_pqrs.map(pqr => {
            const validEntry = Object.entries(pqr).find(
              ([key]) => key !== "is_accepted" && key !== "source" && key !== "comment"
            );
            const [key, value] = validEntry || [];
    
            if (value === "NA" || value === "Not Found") {
              return { ...pqr, is_accepted: false };
            }
    
            if (pqr?.is_accepted === null) {
              return { ...pqr, is_accepted: true };
            }
    
            return pqr;
          });
    
          // Mark bidder as reviewed
          bidder.pqrs_reviewed = true;
        });

        // Mark all bidder pqrs reviewed as true
        state.is_all_bidders_reviewed = true;
        state.callBidderDetailAfterAllBiddersReviewed = true
      }
    },
    markAllBidderPqrsAsDeclined: (state, action) => {
      const unreviewedBidders = state.bidderDetail?.filter(bidder => bidder.pqrs_reviewed === false);

      if(unreviewedBidders && unreviewedBidders.length > 0){
        unreviewedBidders.forEach(bidder => {
          // Update reviewed_pqrs
          bidder.reviewed_pqrs = bidder.reviewed_pqrs.map(pqr => {
            const validEntry = Object.entries(pqr).find(
              ([key]) => key !== "is_accepted" && key !== "source" && key !== "comment"
            );
            const [key, value] = validEntry || [];
    
            if (value === "NA" || value === "Not Found") {
              return { ...pqr, is_accepted: false };
            }
    
            if (pqr?.is_accepted === null) {
              return { ...pqr, is_accepted: false };
            }
    
            return pqr;
          });
    
          // Mark bidder as reviewed
          bidder.pqrs_reviewed = true;
        });

        // Mark all bidder pqrs reviewed as true
        state.is_all_bidders_reviewed = true;
        state.callBidderDetailAfterAllBiddersReviewed = true
      }
    },

  },
  extraReducers: (builder) => {
    builder
      // Fetch Tender Stat
      .addCase(fetchTenderStat.pending, (state) => {
        state.tenderStat = "loading";
        state.tenderStatDetail = {};
      })
      .addCase(fetchTenderStat.fulfilled, (state, action) => {
        state.tenderStat = "succeeded";
        state.tenderStatDetail = action.payload ?? {};
      })
      .addCase(fetchTenderStat.rejected, (state) => {
        state.tenderStat = "failed";
        state.tenderStatDetail = {};
      })
      .addCase(tenderProgressbarStatus.fulfilled, (state, action) => {
        // updating progressbar status
        if ("progress" in state.tenderStatDetail) {
          state.tenderStatDetail.progress = action.payload?.progress ?? state.tenderStatDetail.progress;
        }
        // updating tender status
        if ("status" in state.tenderStatDetail && state.tenderStatDetail?.status !== action.payload?.status) {
          state.tenderStatDetail.status = action.payload?.status ?? state.tenderStatDetail?.status;
        }
      })

      // Fetch Tender Info
      .addCase(fetchTenderInfo.pending, (state) => {
        state.tenderInfo = "loading";
        state.tenderInfoDetail = {};
      })
      .addCase(fetchTenderInfo.fulfilled, (state, action) => {
        state.tenderInfo = "succeeded";
        state.tenderInfoDetail = action.payload;
      })
      .addCase(fetchTenderInfo.rejected, (state) => {
        state.tenderInfo = "failed";
        state.tenderInfoDetail = {};
      })

      // Fetch Bidder Detail
      .addCase(fetchBidderDetail.pending, (state) => {
        state.bidderDetailStatus = "loading";
        state.bidderDetail = [];
        state.is_all_bidders_reviewed = false
      })
      .addCase(fetchBidderDetail.fulfilled, (state, action) => {
        state.bidderDetailStatus = "succeeded";
        state.bidderDetail = action.payload?.bidder_details ?? [];
        state.is_all_bidders_reviewed = action.payload?.is_all_bidders_reviewed ?? false
      })
      .addCase(fetchBidderDetail.rejected, (state) => {
        state.bidderDetailStatus = "failed";
        state.bidderDetail = [];
        state.is_all_bidders_reviewed = false
      })

      // Fetch PQR List
      .addCase(fetchPqrList.pending, (state) => {
        state.pqrListStatus = "loading";
        state.pqrListData = [];
      })
      .addCase(fetchPqrList.fulfilled, (state, action) => {
        state.pqrListStatus = "succeeded";
        state.pqrListData = action.payload.pqrNames; // Use pqrNames from the response
        state.totalPqrCount = action.payload.count;  // Store the count in the state

        // state.pqrListData = pqrList
      })
      .addCase(fetchPqrList.rejected, (state) => {
        state.pqrListStatus = "failed";
        state.pqrListData = [];
      })

      // Generate TQs
      .addCase(generateTqs.pending, (state) => {
        state.generateTQStatus = "loading";
        state.generateTQData = {};
      })
      .addCase(generateTqs.fulfilled, (state, action) => {
        state.generateTQStatus = "succeeded";
        state.generateTQData = action.payload; 
      })
      .addCase(generateTqs.rejected, (state) => {
        state.generateTQStatus = "failed";
        state.generateTQData = {};
      })

      // Reviewed PQRs Comparative Statement
      .addCase(generateCs.pending, (state) => {
        state.reviewedPqrCsStatus = "loading";
        state.reviewedPqrCsUrl = "NA";
      })
      .addCase(generateCs.fulfilled, (state, action) => {
        state.reviewedPqrCsStatus = "succeeded";
        state.reviewedPqrCsUrl = action.payload?.reviewed_pqr_cs_url ?? "NA"; 
      })
      .addCase(generateCs.rejected, (state) => {
        state.reviewedPqrCsStatus = "failed";
        state.reviewedPqrCsUrl = "NA";
      })

      // Send TQs Email
      .addCase(sendBiddersTqsEmail.pending, (state) => {
        state.biddersTqsEmailStatus = "loading";
      })
      .addCase(sendBiddersTqsEmail.fulfilled, (state, action) => {
        state.biddersTqsEmailStatus = "succeeded";
      })
      .addCase(sendBiddersTqsEmail.rejected, (state) => {
        state.biddersTqsEmailStatus = "failed";
      })
  
  },
});

export const {
  updateTenderDocCount,
  updateExistingBidderCount,
  updateNewBidder,
  setActiveTenderType,
  updatePqrValue,
  updatePqrStatus,
  markSingleBidderPqrsAsAccepted,
  markSingleBidderPqrsAsDeclined,
  markAllBidderPqrsAsAccepted,
  markAllBidderPqrsAsDeclined,
  updatecallBidderDetailAfterAllBiddersReviewed
} = tenderDetailSlice.actions;

export default tenderDetailSlice.reducer;
