import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTenderType } from "../../features/TenderDetail/TenderDetailSlice";
import { fetchTenderStatusCount } from "../../features/dashboard/dashboardSlice";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const Dashboard = () => {
  
  const dateFormat = 'YYYY-MM-DD';
  const [currentDate, setCurrentDate] = useState("2024-12-01")
  // Update Current Date
  useEffect(() => {
    function getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    setCurrentDate(getCurrentDate())
  }, [])


  const location = useLocation();
  const dispatch = useDispatch();
  const { activeTenderType } = useSelector((state) => state.tenderDetail);
  const { tenderStatusCount, loading, error } = useSelector((state) => state.tenderStatus);

  const [dates, setDates] = useState([null, null]);

  const isAdmin = localStorage.getItem("is_admin") === "true"; // Check if the user is admin

  useEffect(() => {
    dispatch(setActiveTenderType(""));

    // Dispatching with empty dates initially
    if (!dates[0] || !dates[1]) {
      dispatch(fetchTenderStatusCount({ start_date: "", end_date: "" }));
    }
  }, [dispatch, dates]);

  const handleDateChange = (value) => {
    if (value && value[0] && value[1]) {
      const startDate = dayjs(value[0]).format("YYYY-MM-DD");
      const endDate = dayjs(value[1]).format("YYYY-MM-DD");
      setDates([startDate, endDate]);
    } else {
      setDates([null, null]);
    }
  };

  useEffect(() => {
    // Only dispatch when valid dates are selected
    if (dates[0] && dates[1]) {
      dispatch(fetchTenderStatusCount({ start_date: dates[0], end_date: dates[1] }));
    }
  }, [dates, dispatch]);

  const chartData = tenderStatusCount
    ? Object.keys(tenderStatusCount).map((key) => ({
        name: key,
        "Tenders To Be Processed": tenderStatusCount[key]["Tenders To Be Processed"],
        "Tenders In Progress": tenderStatusCount[key]["Tenders In Progress"],
        "Tenders Completed": tenderStatusCount[key]["Tenders Completed"],
      }))
    : [];

  const pieData = tenderStatusCount
    ? Object.keys(tenderStatusCount).map((key) => ([
        { name: `Tenders To Be Processed`, value: tenderStatusCount[key]["Tenders To Be Processed"] },
        { name: `Tenders In Progress`, value: tenderStatusCount[key]["Tenders In Progress"] },
        { name: `Tenders Completed`, value: tenderStatusCount[key]["Tenders Completed"] },
      ])).flat()
    : [];

  const legendFormatter = (value) => {
    const legendMap = {
      "Tenders To Be Processed": "Tender Evaluation to be Processed",
      "Tenders In Progress": "Tender Evaluation in Progress",
      "Tenders Completed": "Tender Evaluation Completed",
    };
    return legendMap[value] || value;
  };

  const tooltipFormatter = (value, name) => {
    const tooltipMap = {
      "Tenders To Be Processed": "Tender Evaluation to be Processed",
      "Tenders In Progress": "Tender Evaluation in Progress",
      "Tenders Completed": "Tender Evaluation Completed",
    };
    return [value, tooltipMap[name] || name];
  };

  return (
    <div className="w-full h-full bg-[#FFFFFF] flex flex-col items-center gap-6 border border-blue-gray-50 shadow-xl rounded-[10px] p-8">
      {/* Welcome Section */}
      <div className="w-full flex flex-col items-center gap-4 mb-6">
        <h1 className="text-2xl font-extrabold text-center">
          Welcome to the Tender Evaluation Process Web Platform
        </h1>
        <p className="text-center text-gray-600 font-bold">
          Streamline your tender management with our intuitive platform to enhance efficiency, transparency, and decision-making. From document submissions to automated evaluations, we simplify the process every step of the way.
        </p>
      </div>

      {/* Calendar and Chart Section */}
      <div className="relative w-full bg-white border border-gray-300 shadow-md rounded-lg p-4">
        <div className="flex justify-end mb-4">
          <RangePicker
            value={dates[0] && dates[1] ? [dayjs(dates[0]), dayjs(dates[1])] : null}
            onChange={handleDateChange}
            format="DD-MM-YYYY"
            minDate={dayjs('2024-05-01', dateFormat)}
            maxDate={dayjs(currentDate, dateFormat)}
          />
        </div>

        {isAdmin ? (
          <ResponsiveContainer width="100%" height={350}>
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip formatter={tooltipFormatter} />
              <Legend formatter={legendFormatter} />
              <Bar dataKey="Tenders To Be Processed" stackId="a" fill="#8884d8" />
              <Bar dataKey="Tenders In Progress" stackId="a" fill="#82ca9d" />
              <Bar dataKey="Tenders Completed" stackId="a" fill="#ffc658" />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width="100%" height={350}>
            <PieChart>
              <Pie
                data={pieData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={160}
                fill="#8884d8"
                label
              >
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={['#8884d8', '#82ca9d', '#ffc658'][index % 3]} />
                ))}
              </Pie>
              <Tooltip formatter={tooltipFormatter} />
              <Legend
                formatter={legendFormatter}
                layout="vertical"
                verticalAlign="bottom"
                align="right"
                wrapperStyle={{
                  paddingTop: "20px", // Adjust the padding if necessary
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
